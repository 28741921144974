import nanoid from "nanoid";
import {get, set, sortBy, sumBy, remove, unset} from "lodash";
import moment from "moment";
import {ICI, LEASE, MULTI_FAMILY, RESIDENTIAL_RENT, VACANT_LAND, PROPERTY_TYPES} from "./propertyTypes";
import {propertyNameShort, propertyNameTitle} from "./propertyTypes";
import {Comp} from "./objects";

export class Unit {
    static FT = {
        label: "Feet", symbol: "ft"
    };
    static LITRES = {
        label: "Litres", symbol: "L"
    };
    static FT_TO_METRE = 0.3048;
}

export class AreaUnit {
    static FT2 = {
        label: "Square Feet", symbol: "ft<sup>2</sup>", factor: 2.29568e-5,
        other: () => AreaUnit.Acres,
        format: v => Field.FT2_FORMATTER.format(v)
    };
    static Acres = {
        label: "Acres", symbol: "acres", factor: 43560,
        other: () => AreaUnit.FT2,
        format: v => Field.ACRE_FORMATTER.format(v)
    };
    static None = {
        label: "N/A - Strata", symbol: "n/a",
        other: () => AreaUnit.None,
        format: v => v
    };

    static FT2_TO_M2 = 0.092903;
    static ACRE_TO_M2 = 4046.86;

    static get(u) {
        switch(u) {
            case AreaUnit.Acres.label:
            case AreaUnit.Acres.symbol:
                return AreaUnit.Acres;
            case AreaUnit.None.label:
                return AreaUnit.None;
            default:
                return AreaUnit.FT2;
        }
    }
}

export class FieldType {
    static STRING = "string";
    static TEXT = "text";
    static CURRENCY = "currency";
    static AREA = "area";
    static PERCENT = "percent";
    static INTEGER = "integer";
    static NUMBER = "number";
    static DATE = "date";
    static BOOLEAN = "boolean";
    static GEO = "geo";

    static PROPERTY_TYPE = "property_type";

    static isNumeric(t) {
        return [FieldType.CURRENCY, FieldType.AREA, FieldType.NUMBER, FieldType.INTEGER, FieldType.PERCENT].includes(t);
    }
}

export class FieldLabel {
    static DEFAULT = "default";
    static FORM = "form";
    static SHORT = "short";
    static REPORT_HEADER = "report_header";
    static REPORT_BODY = "report_body";
    static SEARCH = "search";
}

export class FieldOption {
    constructor(key) {
        this.key = key;
    }

    get(options) {
        return options[this.key];
    }

    is(options) {
        return !!this.get(options);
    }
}

export class FieldOptions {
    static METRIC = new FieldOption("metric");
    static COMP = new FieldOption("comp");
}

export class BasementFinish {
    static INPUT_SPECIFY = "Specify";
    static MODE_PERCENT = "%";
    static MODE_SF = "sf";
}

export class PhysicalPropertyType {
    static RETAIL_OFFICE = "Retail/Office";
    static INDUSTRIAL = "Industrial";
    static MIXED_USE =  "Mixed Use";
    static INSTITUTIONAL = "Institutional";
    static FARM = "Farm";
    static OTHER_COMMERCIAL = "Other Commercial";
    static AGRICULTURAL_FARMLAND = "Agricultural/Farmland";

    static RESIDENTIAL_DEV_LANDS = "Residential Development Lands";

    static RESIDENTIAL = "Residential";

    static OFFICE = "Office";
    static RETAIL = "Retail";
    static OFFICE_RETAIL = "Office/Retail";
    static VACANT_LAND = "Vacant Land";
    static COMMERCIAL = "Commercial";
    static COMMERCIAL_RESIDENTIAL = "Commercial/Residential";

    static isResidentialDevLands(obj) {
        return Field.PHYSICAL_PROPERTY_TYPE.get(obj) === PhysicalPropertyType.RESIDENTIAL_DEV_LANDS;
    }

    static isAgriculturalFarmland(obj) {
        return Field.PHYSICAL_PROPERTY_TYPE.get(obj) === PhysicalPropertyType.AGRICULTURAL_FARMLAND;
    }

    static isResidential(obj) {
        return Field.PHYSICAL_PROPERTY_TYPE.get(obj) === PhysicalPropertyType.RESIDENTIAL;
    }

    static isIndustrial(obj) {
        return Field.PHYSICAL_PROPERTY_TYPE.get(obj) === PhysicalPropertyType.INDUSTRIAL;
    }
}

export class PhysicalPropertySubType {
    static CAR_WASH = "Car Wash";
    static DENTAL_OFFICE = "Dental Office";
    static GOLF_COURSE = "Golf Course";
    static HOTEL = "Hotel";
    static MOTEL = "Motel";
    static MARINE_FACILITIES = "Marine Facilities";
    static MEDICAL_OFFICE = "Medical Office";
    static MOBILE_HOME_PARK = "Mobile Home Park";
    static PROFESSIONAL_OFFICE = "Professional Office";
    static WAREHOUSE = "Warehouse";
    static WAREHOUSE_OFFICE = "Warehouse/Office";
    static SERVICE_STATION = "Service Station";
    static SINGLE_FAMILY_DWELLING = "Single Family Dwelling";

    static isMobileHomePark(obj) {
        return Field.PHYSICAL_PROPERTY_SUBTYPE.get(obj) === PhysicalPropertySubType.MOBILE_HOME_PARK;
    }

    static isServiceStation(obj) {
        return Field.PHYSICAL_PROPERTY_SUBTYPE.get(obj) === PhysicalPropertySubType.SERVICE_STATION;
    }

    static isCarWash(obj) {
        return Field.PHYSICAL_PROPERTY_SUBTYPE.get(obj) === PhysicalPropertySubType.CAR_WASH;
    }

    static isHotelOrMotel(obj) {
        return Field.PHYSICAL_PROPERTY_SUBTYPE.get(obj) === PhysicalPropertySubType.HOTEL ||
            Field.PHYSICAL_PROPERTY_SUBTYPE.get(obj) === PhysicalPropertySubType.MOTEL;
    }

    static isMarineFacilities(obj) {
        return Field.PHYSICAL_PROPERTY_SUBTYPE.get(obj) === PhysicalPropertySubType.MARINE_FACILITIES;
    }

    static isWarehouse(obj) {
        return (Field.PHYSICAL_PROPERTY_SUBTYPE.get(obj) === PhysicalPropertySubType.WAREHOUSE) ||
               (Field.PHYSICAL_PROPERTY_SUBTYPE.get(obj) === PhysicalPropertySubType.WAREHOUSE_OFFICE);
    }

    static isGolfCourse(obj) {
        return Field.PHYSICAL_PROPERTY_SUBTYPE.get(obj) === PhysicalPropertySubType.GOLF_COURSE;
    }

    static isSingleFamilyDwelling(obj) {
        return Field.PHYSICAL_PROPERTY_SUBTYPE.get(obj) === PhysicalPropertySubType.SINGLE_FAMILY_DWELLING;
    }
}

export class PhysicalDevelopmentType {
    static SINGLE_FAMILY = "Single Family";
    static LOW_DENSITY = "Low Density / Townhouse";
    static MEDIUM_DENSITY = "Medium Density / Low-rise";
    static HIGH_DENSITY = "High Density / High-rise";

    static isSingleFamily(obj) {
        return Field.PHYSICAL_DEVELOPMENT_TYPE.get(obj) === PhysicalDevelopmentType.SINGLE_FAMILY;
    }
}

export class VacationCollectionLossMode {
    static PERCENT = "percent";
    static AMOUNT = "amount";
}

export class ConfirmationSource {
    static MLS = "MLS";
    static ASSESSMENT_DATA = "Assessment Data";
    static APPRAISAL_DATA = "Appraisal Data";
    static REALTOR = "Realtor";
    static GEOWAREHOUSE = "GeoWarehouse";
    static CURRENT_LISTING = "Current Listing";
    static OTHER = "Other";
    static UNCONFIRMED = "Unconfirmed";

    static isCurrentListing(obj) {
        return Field.CONFIRMATION_IS_CURRENT_LISTING.get(obj)
    }

    static isUnconfirmed(obj) {
        const s = Field.CONFIRMATION_SOURCE.get(obj);
        return s === ConfirmationSource.UNCONFIRMED || !s;
    }

    static setUnconfirmed(obj) {
        Field.CONFIRMATION_SOURCE.set(obj, ConfirmationSource.UNCONFIRMED);
    }

    static setIfUnconfirmed(obj, target) {
        if (ConfirmationSource.isUnconfirmed(obj)) {
            ConfirmationSource.setUnconfirmed(target);
        }
    }
}

export class PhotoCategory {
    static AERIAL = "Aerial";
    static EXTERIOR = "Exterior";
    static INTERIOR = "Interior";

    static values() {
        return [PhotoCategory.AERIAL, PhotoCategory.EXTERIOR, PhotoCategory.INTERIOR];
    }
}

export class PhotoOrientation {
    static FRONT = "Front";
    static REAR = "Rear";
    static SIDE = "Side";

    static ROOM1 = "Room 1";
    static ROOM2 = "Room 2";
    static ROOM3 = "Room 3";
    static ROOM4 = "Room 4";

    static values(category) {
        switch(category) {
            case PhotoCategory.EXTERIOR:
                return [PhotoOrientation.FRONT, PhotoOrientation.REAR, PhotoOrientation.SIDE];
            case PhotoCategory.INTERIOR:
                return [PhotoOrientation.ROOM1, PhotoOrientation.ROOM2, PhotoOrientation.ROOM3, PhotoOrientation.ROOM4];
            default:
                return [];
        }
    }
}

export class Field {

    static TYPE = new Field("type", {
        [FieldLabel.DEFAULT]: "Comp Type",
        [FieldLabel.REPORT_HEADER]: "Property Use Type"
    }, {
        type: FieldType.PROPERTY_TYPE,
        values: PROPERTY_TYPES.map(p => [p, propertyNameShort(p)]),
        indexed: true,
        copyable: false
    });

    static COMP_ID = new Field("comp_id", {
        [FieldLabel.DEFAULT]: "Comp ID",
    }, {type: FieldType.STRING, copyable: false});

    static CREATED = new Field("created", {
        [FieldLabel.DEFAULT]: "Date Created",
        [FieldLabel.SHORT]: "Created"
    }, {type: FieldType.DATE, indexed: true, copyable: false});

    static UPDATED = new Field("updated", {
        [FieldLabel.DEFAULT]: "Date Last Modified",
        [FieldLabel.SHORT]: "Last Modified"
    }, {type: FieldType.DATE, indexed: true, copyable: false});

    static COMMENTS = new Field("comments", "Comments", {type: FieldType.TEXT});
    static BRIEF = new Field("brief", "Brief Comments", {type: FieldType.TEXT});

    static NOTES = new Field("notes", "Notes");
    static TITLE = new Field("title", "Title", {copyable: false});

    static DATE = new Field("date", {
        [FieldLabel.DEFAULT]: o => {
            if (!FieldOptions.COMP.is(o)) return "Date";

            const comp = FieldOptions.COMP.get(o);
            const isListing = Field.CONFIRMATION_IS_CURRENT_LISTING.get(comp);
            if (isListing) return Field.CONFIRMATION_LISTING_DATE.label(o);
            else {
                const type = Field.TYPE.get(comp);
                switch(type) {
                    case RESIDENTIAL_RENT:
                        return Field.CREATED.label(o);
                    case LEASE:
                        return Field.LEASE_START_DATE.label(o);
                    default:
                        return Field.SALES_DATE.label(o);
                }
            }
        }
    }, {
        type: FieldType.DATE,
        derive: obj => {
            const isListing = Field.CONFIRMATION_IS_CURRENT_LISTING.get(obj);
            if (isListing) {
                return Field.CONFIRMATION_LISTING_DATE.get(obj);
            }
            else {
                const type = Field.TYPE.get(obj);
                switch(type) {
                    case RESIDENTIAL_RENT:
                        return Field.CREATED.get(obj);
                    case LEASE:
                        return Field.LEASE_START_DATE.get(obj);
                    default:
                        return Field.SALES_DATE.get(obj);
                }
            }
        },
        dependsOn: () => [Field.CONFIRMATION_IS_CURRENT_LISTING, Field.CONFIRMATION_LISTING_DATE, Field.CREATED,
            Field.LEASE_START_DATE, Field.SALES_DATE]
    });

    static VALUE = new Field("value", {
        [FieldLabel.DEFAULT]: o => {
            if (!FieldOptions.COMP.is(o)) return "Value";

            const comp = FieldOptions.COMP.get(o);
            return comp.type === RESIDENTIAL_RENT ? Field.FINANCIAL_MONTHLY_RENT.label() :
              comp.type === LEASE ? Field.LEASE_AVERAGE_RATE_OVER_TERM.label(FieldLabel.SHORT) : Field.SALES_PRICE.label();
        }
    }, {
        type: FieldType.CURRENCY,
        derive: obj => {
            return obj.type === RESIDENTIAL_RENT ? Field.FINANCIAL_MONTHLY_RENT.get(obj) :
              obj.type === LEASE ? Field.LEASE_AVERAGE_RATE_OVER_TERM.get(obj) : Field.SALES_PRICE.get(obj);
        }
    });

    static GEO = new Field("geo", "Coordinates", {type: FieldType.GEO})

    static USERS = new Field("users", "Users", {type: FieldType.STRING});

    static GENERAL_DEVELOPMENT_NAME = new Field("general.development_name", "Development Name");
    static GENERAL_LEGAL_DESCRIPTION = new Field("general.legal_description", "Legal Description");
    static GENERAL_PID = new Field("general.pid", "PID/PIN #");
    static GENERAL_LOCATION = new Field("general.location", "Location", {
        "placeholder": "eg: Intersect of 3rd Street & Fifth Ave"
    });

    static ADDRESS_STREET = new Field("address.street", {
        [FieldLabel.DEFAULT]: "Property Address",
        [FieldLabel.SHORT]: "Street Address"
    });
    static ADDRESS_UNIT = new Field("address.unit", {
        [FieldLabel.DEFAULT]: "Unit Number",
        [FieldLabel.SHORT]: "Unit #"
    });
    static ADDRESS_CITY = new Field("address.city", "City");
    static ADDRESS_MUNICIPALITY = new Field("address.municipality", "Municipality");
    static ADDRESS_COUNTRY = new Field("address.country", "Country");
    static ADDRESS_POSTAL = new Field("address.postal", "Postal Code");
    static ADDRESS_REGION = new Field("address.region", {
        [FieldLabel.DEFAULT]: "Province",
        "en_US": {
            [FieldLabel.DEFAULT]: "State"
        }
    });
    static ADDRESS_STREET_WITH_CITY = new Field("address.street_with_city", {
        [FieldLabel.DEFAULT]: "Property Address with City",
        [FieldLabel.SHORT]: "Street Address with City"
    }, {
        derive: obj => {
            return `${Field.ADDRESS_STREET.get(obj)}, ${Field.ADDRESS_CITY.get(obj)}`;
        },
        dependsOn: () => [Field.ADDRESS_STREET, Field.ADDRESS_CITY]
    });

    static CONFIRMATION_COMMENTS = new Field("confirmation.comments", {
        [FieldLabel.DEFAULT]: "Confirmation Comments",
        [FieldLabel.SHORT]: "Comments"
    }, {type: FieldType.TEXT});

    static CONFIRMATION_DATE = new Field("confirmation.date", {
        [FieldLabel.DEFAULT]: "Confirmation Date",
        [FieldLabel.SHORT]: "Confirmed",
        [FieldLabel.REPORT_HEADER]: "Confirmed",
    }, {type: FieldType.DATE});

    static CONFIRMATION_LISTING_DATE = new Field("confirmation.listing_date", "Listing Date", {
        type: FieldType.DATE
    });

    static CONFIRMATION_SOURCE = new Field("confirmation.source", {
        [FieldLabel.DEFAULT]: "Confirmation Source"
    }, {
        values: [ConfirmationSource.MLS, ConfirmationSource.ASSESSMENT_DATA, ConfirmationSource.REALTOR, ConfirmationSource.GEOWAREHOUSE,
            ConfirmationSource.OTHER, ConfirmationSource.CURRENT_LISTING, null, ConfirmationSource.UNCONFIRMED],
        indexed: true
    });

    static CONFIRMATION_STATUS = new Field("confirmation.status", {
        [FieldLabel.DEFAULT]: "Confirmation Status"
    }, {
        type: FieldType.STRING,
        derive: obj => {
            return ConfirmationSource.isUnconfirmed(obj) ? "Unconfirmed" :
              (ConfirmationSource.isCurrentListing(obj) ? "Current Listing" : "Confirmed");
        }
    });

    static CONFIRMATION_IS_CURRENT_LISTING = new Field("confirmation.is_current_listing", {
        [FieldLabel.DEFAULT]: "Is Current Listing",
    }, {
        type: FieldType.BOOLEAN,
        derive: obj => {
            return Field.CONFIRMATION_SOURCE.get(obj) === ConfirmationSource.CURRENT_LISTING
        },
    });

    static CONFIRMATION_MLS_NUM = new Field("confirmation.mls_num", {
        [FieldLabel.DEFAULT]: "MLS Number",
        [FieldLabel.SHORT]: "MLS #",
        [FieldLabel.REPORT_HEADER]: "MLS #",
    });

    static CONFIRMATION_IS_CONFIDENTIAL = new Field("confirmation.is_confidential", "Confidential", {
        type: FieldType.BOOLEAN
    });

    static IMPROVEMENT_BASEMENT_AREA = new Field("improvement.basement_area", {
        [FieldLabel.DEFAULT]: "Basement Area - Square Feet",
        [FieldLabel.SHORT]: o => `Basement Area (${FieldOptions.METRIC.is(o)?"sqm":"sqft"})`,
        [FieldLabel.REPORT_HEADER]: o => `Basement Area (${FieldOptions.METRIC.is(o)?"sqft":"sqm"})`,
    }, {
            type: FieldType.AREA,
            unit: AreaUnit.FT2,
            extendUpdate: (value, comp) => {
                const finish = Field.IMPROVEMENT_BASEMENT_FINISH.get(comp);
                if (finish === BasementFinish.INPUT_SPECIFY) {
                    if (value) {
                        const mode = Field.IMPROVEMENT_BASEMENT_FINISH_MODE.get(comp);
                        switch(mode) {
                            case BasementFinish.MODE_PERCENT:
                                const percent = Field.IMPROVEMENT_BASEMENT_FINISH_PERCENT.get(comp);
                                if (percent) {
                                    Field.IMPROVEMENT_BASEMENT_FINISH_SF.update(value * percent / 100.0, comp);
                                }
                                break;
                            case BasementFinish.MODE_SF:
                                const sf = Field.IMPROVEMENT_BASEMENT_FINISH_SF.get(comp);
                                if (sf) {
                                    Field.IMPROVEMENT_BASEMENT_FINISH_PERCENT.update(sf / value * 100.0, comp);
                                }
                                break;
                            default:
                        }
                    }
                    else {
                        Field.IMPROVEMENT_BASEMENT_FINISH_PERCENT.update(" ", comp);
                        Field.IMPROVEMENT_BASEMENT_FINISH_SF.update(" ", comp);
                        Field.IMPROVEMENT_BASEMENT_FINISH_MODE.update(null, comp);
                    }
                }
            },
        });
    static IMPROVEMENT_BASEMENT_FINISH = new Field("improvement.basement_finish", "Basement Finish", {
        values: ["Unfinished", "Partly Finished", "Fully Finished", "No Basement", BasementFinish.INPUT_SPECIFY]
    });

    static IMPROVEMENT_BASEMENT_FINISH_MODE = new Field("improvement.basement_finish_mode", "Basement Finish mode", {
        values: [BasementFinish.MODE_PERCENT, BasementFinish.MODE_SF]
    });

    static IMPROVEMENT_BASEMENT_FINISH_PERCENT = new Field("improvement.basement_finish_percent", {
        [FieldLabel.DEFAULT]: "Basement Percent Finished",
        [FieldLabel.SHORT]: "Basement % Finished",
        [FieldLabel.REPORT_HEADER]: "Basement % Finished",
    }, {
        type: FieldType.PERCENT,
        extendUpdate: (value, comp) => {
            if (value) {
                Field.IMPROVEMENT_BASEMENT_FINISH_MODE.update(BasementFinish.MODE_PERCENT, comp);
                const area = Field.IMPROVEMENT_BASEMENT_AREA.get(comp);
                if (area) {
                    Field.IMPROVEMENT_BASEMENT_FINISH_SF.update(value * area / 100.0, comp);
                }
            }
            else {
                Field.IMPROVEMENT_BASEMENT_FINISH_SF.update(null, comp);
                Field.IMPROVEMENT_BASEMENT_FINISH_MODE.update(null, comp);
            }
        }
    });
    static IMPROVEMENT_BASEMENT_FINISH_SF = new Field("improvement.basement_finish_sf", {
        [FieldLabel.DEFAULT]: o => `Basement ${FieldOptions.METRIC.is(o)?"Square Metres":"Square Feet"} Finished`,
        [FieldLabel.SHORT]: o => `Basement ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"} Finished`,
        [FieldLabel.REPORT_HEADER]: o => `Basement ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"} Finished`,
    }, {
        type: FieldType.AREA,
        unit: AreaUnit.FT2,
        extendUpdate: (value, comp) => {
            if (value) {
                Field.IMPROVEMENT_BASEMENT_FINISH_MODE.update(BasementFinish.MODE_SF, comp);
                const area = Field.IMPROVEMENT_BASEMENT_AREA.get(comp);
                if (area) {
                    Field.IMPROVEMENT_BASEMENT_FINISH_PERCENT.update(value / area * 100.0, comp);
                }
            }
            else {
                Field.IMPROVEMENT_BASEMENT_FINISH_PERCENT.update(null, comp);
                Field.IMPROVEMENT_BASEMENT_FINISH_MODE.update(null, comp);
           }
        },
        toMetric: AreaUnit.FT2_TO_M2
    });

    static IMPROVEMENT_BUILDING_AREA_SOURCE = new Field(
        "improvement.building_area_source", "Building Area Source", {
        values: ["Measurement", "Building plans", "Assessment", "MPAC", "MLS", "Aerial Imagery", "Strata Plan"]
    });

    static IMPROVEMENT_BUILDING_CLASS = new Field("improvement.building_class", "Building Class", {
        values: [
            "A: Fireproof Structural Steel Frame",
            "B: Reinforced Concrete Frame",
            "C: Masonry Bearing Walls",
            "D: Wood or Steel-Framed Exterior Walls",
            "Mixed",
            "Other"
        ]
    });

    static IMPROVEMENT_CONSTRUCTION_QUALITY = new Field(
        "improvement.construction_quality", "Construction Quality", {
            values: ["Excellent", "Good", "Average", "Fair", "Poor"]
        });

    static IMPROVEMENT_GROSS_BUILDING_AREA = new Field(
        "improvement.gross_building_area", {
            [FieldLabel.DEFAULT]: o => `Gross Building Area Excluding Basement - ${FieldOptions.METRIC.is(o)?"Square Metres":"Square Feet"}`,
            [FieldLabel.SHORT]: o => `Gross Building Area (${FieldOptions.METRIC.is(o)?"SQM":"SQFT"})`,
            [FieldLabel.REPORT_HEADER]: o => `Gross Building Area w/o Bsmt (${FieldOptions.METRIC.is(o)?"SQM":"SQFT"})`,
            [FieldLabel.SEARCH]: o => "Building Size",
        }, {
            type: FieldType.AREA,
            unit: AreaUnit.FT2,
            toMetric: AreaUnit.FT2_TO_M2,
            indexed: true
        });

    static IMPROVEMENT_MAIN_FLOOR_PLATE_AREA = new Field(
        "improvement.main_floor_plate_area",  {
            [FieldLabel.DEFAULT]: o => `Main Floor Plate Area - Square ${FieldOptions.METRIC.is(o)?"Metre":"Feet"}`,
            [FieldLabel.SHORT]: o => `Main Floor Plate (${FieldOptions.METRIC.is(o)?"SQM":"SQFT"})`,
            [FieldLabel.REPORT_HEADER]: o => `Main Floor Plate (${FieldOptions.METRIC.is(o)?"SQM":"SQFT"})`
        }, {
            type: FieldType.AREA,
            unit: AreaUnit.FT2,
            toMetric: AreaUnit.FT2_TO_M2
        });

    static IMPROVEMENT_MEZZANINE_AREA = new Field(
        "improvement.mezzanine_area",  {
            [FieldLabel.DEFAULT]: o => `Mezzanine Area - Square ${FieldOptions.METRIC.is(o)?"Metre":"Feet"}`,
            [FieldLabel.SHORT]: o => `Mezzanine (${FieldOptions.METRIC.is(o)?"SQM":"SQFT"})`,
            [FieldLabel.REPORT_HEADER]: o => `Mezzanine (${FieldOptions.METRIC.is(o)?"SQM":"SQFT"})`
        }, {
            type: FieldType.AREA,
            unit: AreaUnit.FT2,
            toMetric: AreaUnit.FT2_TO_M2
        });

    static IMPROVEMENT_OFFICE_AREA = new Field(
      "improvement.office_area",  {
          [FieldLabel.DEFAULT]: o => `Office Area - Square ${FieldOptions.METRIC.is(o)?"Metre":"Feet"}`,
          [FieldLabel.SHORT]: o => `Office Area - (${FieldOptions.METRIC.is(o)?"SQM":"SQFT"})`
      }, {
          type: FieldType.AREA,
          unit: AreaUnit.FT2,
          toMetric: AreaUnit.FT2_TO_M2
      });

    static IMPROVEMENT_OFFICE_FINISH_PERCENT = new Field(
      "improvement.office_finish_percent",  {
          [FieldLabel.DEFAULT]: o => `Office Percent Finished`,
          [FieldLabel.SHORT]: o => `Office % Finished`
      }, {
          type: FieldType.PERCENT,
      });

    static IMPROVEMENT_TOTAL_PROPOSED_GROSS_FLOOR_AREA = new Field(
        "improvement.proposed_gross_floor_area",  {
            [FieldLabel.DEFAULT]: o => `Proposed Gross Floor Area - Square ${FieldOptions.METRIC.is(o)?"Metre":"Feet"}`,
            [FieldLabel.SHORT]: o => `Proposed GFA (${FieldOptions.METRIC.is(o)?"SQM":"SQFT"})`,
        }, {
            type: FieldType.AREA,
            unit: AreaUnit.FT2,
            toMetric: AreaUnit.FT2_TO_M2
        });

    static IMPROVEMENT_NUM_BUILDINGS = new Field(
        "improvement.num_buildings", "Number of Buildings", {type: FieldType.INTEGER});
    static IMPROVEMENT_NUM_PARKING_SPACES = new Field(
        "improvement.num_parking_spaces", "Number of Parking Spaces");
    static IMPROVEMENT_NUM_STORIES = new Field(
        "improvement.num_stories", "Number of Stories", {type: FieldType.INTEGER});

    static IMPROVEMENT_NUM_UNITS = new Field(
        "improvement.num_units", "Number of Units", {type: FieldType.INTEGER, indexed: true});

    static IMPROVEMENT_NUM_PADS = new Field(
        "improvement.num_pads", "Number of Pads", {type: FieldType.INTEGER});

    static IMPROVEMENT_NUM_HOLES = new Field(
        "improvement.num_holes", "Number of Holes", {type: FieldType.INTEGER});

    static IMPROVEMENT_COURSE_PAR = new Field(
        "improvement.course_par", "Course Par", {type: FieldType.INTEGER});

    static IMPROVEMENT_COURSE_YARDAGE = new Field(
        "improvement.course_yardage", "Course Yardage", {
            type: FieldType.INTEGER,
            unit: Unit.FT,
            toMetric: AreaUnit.FT2_TO_M2
        });

    static IMPROVEMENT_PARKING_RATIO = new Field(
      "improvement.parking_ratio", "Parking Ratio", {
          type: FieldType.NUMBER,
          derive: obj => {
              const numSpaces = parseInt(Field.IMPROVEMENT_NUM_PARKING_SPACES.get(obj));
              const area = Field.IMPROVEMENT_RENTABLE_AREA.get(obj);
              if (numSpaces && area) {
                  const factor = area / 1000;
                  return numSpaces / factor;
              }
              return 0;
          },
          dependsOn: () => [Field.IMPROVEMENT_RENTABLE_AREA, Field.IMPROVEMENT_NUM_PARKING_SPACES]
      });

    static IMPROVEMENT_HAS_SUITE_MIX = new Field(
        "improvement.has_suite_mix", "Has Suite Mix", {type: FieldType.BOOLEAN});

    static IMPROVEMENT_NUM_BACHELOR_UNITS = new Field(
        "improvement.num_bachelor_units", "Number of Bachelor Units", {
            type: FieldType.INTEGER
        });
    static IMPROVEMENT_NUM_1BEDROOM_UNITS = new Field(
        "improvement.num_1bedroom_units", "Number of 1 Bedroom Units", {
            type: FieldType.INTEGER
        });
    static IMPROVEMENT_NUM_2BEDROOM_UNITS = new Field(
        "improvement.num_2bedroom_units", "Number of 2 Bedroom Units", {
            type: FieldType.INTEGER
        });
    static IMPROVEMENT_NUM_3BEDROOM_UNITS = new Field(
        "improvement.num_3bedroom_units", "Number of 3 Bedroom Units", {
            type: FieldType.INTEGER
        });
    static IMPROVEMENT_NUM_4BEDROOM_UNITS = new Field(
      "improvement.num_4bedroom_units", "Number of 4 Bedroom Units", {
          type: FieldType.INTEGER
      });

    static IMPROVEMENT_NUM_SELF_SERVICE_BAYS = new Field(
        "improvement.num_selfservice_bays", {
            [FieldLabel.DEFAULT]: o => "Number of Self-Service Bays",
            [FieldLabel.SHORT]: o => "Self-Service Bays",

        }, {
            type: FieldType.INTEGER
        });

    static IMPROVEMENT_NUM_AUTO_TUNNEL_BAYS = new Field(
        "improvement.num_autotunnel_bays", {
            [FieldLabel.DEFAULT]: o => "Number of Auto Tunnel Bays",
            [FieldLabel.SHORT]: o => "Auto Tunnel Bays",
        }, {
            type: FieldType.INTEGER
        });

    static IMPROVEMENT_NUM_BAYS = new Field(
        "improvement.num_bays", {
            [FieldLabel.DEFAULT]: o => "Total Number of Bays",
            [FieldLabel.SHORT]: o => "Number of Bays",
        }, {
            type: FieldType.INTEGER,
            derive: obj => {
                const self = Field.IMPROVEMENT_NUM_SELF_SERVICE_BAYS.get(obj) || 0;
                const auto = Field.IMPROVEMENT_NUM_AUTO_TUNNEL_BAYS.get(obj) || 0;
                return self + auto;
            },
            dependsOn: () => [Field.IMPROVEMENT_NUM_SELF_SERVICE_BAYS, Field.IMPROVEMENT_NUM_AUTO_TUNNEL_BAYS]
        });

    static IMPROVEMENT_LOADING_BAYS = new Field(
      "improvement.loading_bays", "Loading Bays");

    static IMPROVEMENT_NUM_ROOMS = new Field(
        "improvement.num_rooms", {
            [FieldLabel.DEFAULT]: o => "Total Number of Rooms",
            [FieldLabel.SHORT]: o => "Number of Rooms",
        }, {
            type: FieldType.INTEGER,
        });

    static IMPROVEMENT_CLEARING_HEIGHT = new Field(
      "improvement.clearing_height", {
          [FieldLabel.DEFAULT]: o => `Clearing Height - ${FieldOptions.METRIC.is(o) ? "Metres" : "Feet"}`,
          [FieldLabel.SHORT]: o => `Clearing Height (${FieldOptions.METRIC.is(o) ? "m" : "ft"})`
      }, {
          type: FieldType.INTEGER,
          unit: Unit.FT,
          toMetric: Unit.FT_TO_METRE
      });

    static IMPROVEMENT_ROOMS_PER_UNIT = new Field(
      "improvement.rooms_per_unit", {
          [FieldLabel.DEFAULT]: o => `Rooms Per Unit`,
      }, {
          type: FieldType.NUMBER,
          derive: obj => {
              const rooms = Field.IMPROVEMENT_NUM_ROOMS.get(obj) || 0;
              const units = Field.IMPROVEMENT_NUM_UNITS.get(obj) || 0;
              if (rooms && units) {
                  return rooms / units;
              }
              return 0;
          },
          dependsOn: () => [Field.IMPROVEMENT_NUM_ROOMS, Field.IMPROVEMENT_NUM_UNITS]
      });

    static IMPROVEMENT_SUITE_MIX = new Field(
        "improvement.suite_mix", "Suite Mix", {
            derive: obj => {
                const hasSuiteMix = Field.IMPROVEMENT_HAS_SUITE_MIX.get(obj) === true;
                if (hasSuiteMix) {
                    const numBachelor = Field.IMPROVEMENT_NUM_BACHELOR_UNITS.get(obj) || 0;
                    const num1Bed = Field.IMPROVEMENT_NUM_1BEDROOM_UNITS.get(obj) || 0;
                    const num2Bed = Field.IMPROVEMENT_NUM_2BEDROOM_UNITS.get(obj) || 0;
                    const num3Bed = Field.IMPROVEMENT_NUM_3BEDROOM_UNITS.get(obj) || 0;
                    const num4Bed = Field.IMPROVEMENT_NUM_4BEDROOM_UNITS.get(obj) || 0;

                    const result = [];
                    if (numBachelor) result.push(`Bach: ${numBachelor}`);
                    if (num1Bed) result.push(`1 Bed: ${num1Bed}`);
                    if (num2Bed) result.push(`2 Bed: ${num2Bed}`);
                    if (num3Bed) result.push(`3 Bed: ${num3Bed}`);
                    if (num4Bed) result.push(`4 Bed: ${num4Bed}`);

                    return result.join("; ");
                }
                return null;
            },
            dependsOn: () => [Field.IMPROVEMENT_HAS_SUITE_MIX, Field.IMPROVEMENT_NUM_BACHELOR_UNITS,
                Field.IMPROVEMENT_NUM_1BEDROOM_UNITS, Field.IMPROVEMENT_NUM_2BEDROOM_UNITS, Field.IMPROVEMENT_NUM_3BEDROOM_UNITS, Field.IMPROVEMENT_NUM_4BEDROOM_UNITS]
        });

    static IMPROVEMENT_PARKING_TYPE = new Field(
        "improvement.parking_type", "Parking Type", {
            // values:
            values: comp => comp.type === RESIDENTIAL_RENT ?
                ["Street Parking", "Open Parking", "Carport", "Driveway", "1 Car Garage", "2 Car Garage", "3 Car Garage", "4 Car Garage"] :
                ["Street Parking", "Surface", "Underground", "Roof-top", "Structure", "No Assigned Parking", "Other"],
        }
    );

    static IMPROVEMENT_PARKING_COMMENTS = new Field(
        "improvement.parking_comments", "Parking Comments"
    );

    static IMPROVEMENT_PROPERTY_CONDITION = new Field(
        "improvement.property_condition", "Property Condition", {
            values: ["Excellent", "Very Good", "Good", "Above Average", "Average", "Below Average", "Fair", "Poor"]
        });

    static IMPROVEMENT_RENTABLE_AREA = new Field(
        "improvement.rentable_area", {
        [FieldLabel.DEFAULT]: o => `Rentable Area Excluding Basement - ${FieldOptions.METRIC.is(o)?"Square Metres":"Square Feet"}`,
        [FieldLabel.SHORT]: o => `Rentable Area (${FieldOptions.METRIC.is(o)?"SQM":"SQFT"})`,
        [FieldLabel.REPORT_HEADER]: o => `Rentable Area (${FieldOptions.METRIC.is(o)?"SQM":"SQFT"}) - w/o Bsmt`
    }, {
        type: FieldType.AREA,
        unit: AreaUnit.FT2,
        toMetric: AreaUnit.FT2_TO_M2
    });

    static IMPROVEMENT_RENTABLE_AREA_SOURCE = new Field(
        "improvement.rentable_area_source", "Source of Rentable Area");

    static IMPROVEMENT_LEASED_AREA = new Field(
        "improvement.leased_area", {
            [FieldLabel.DEFAULT]: o => `Leased Area Excluding Basement - ${FieldOptions.METRIC.is(o)?"Square Metres":"Square Feet"}`,
            [FieldLabel.SHORT]: o => `Leased Area (${FieldOptions.METRIC.is(o)?"SQM":"SQFT"})`,
            [FieldLabel.REPORT_HEADER]: o => `Leased Area (${FieldOptions.METRIC.is(o)?"SQM":"SQFT"}) - w/o Bsmt`
        }, {
            type: FieldType.AREA,
            unit: AreaUnit.FT2,
            toMetric: AreaUnit.FT2_TO_M2,
            indexed: true
        });

    static IMPROVEMENT_LEASED_AREA_SOURCE = new Field(
    "improvement.leased_area_source", "Source of Leased Area", {
        // values: ["Measurement", "Building plans", "Assessment", "MLS"]
    });

    static IMPROVEMENT_LAND_TO_BUILDING_RATIO = new Field(
        "improvement.land_building_ratio", {
            [FieldLabel.DEFAULT]: "Land to Building Ratio",
            [FieldLabel.FORM]: "Land to Building Ratio (Site Coverage)",
        }, {
            type: FieldType.PERCENT,
            derive: obj => {
                let total = Field.PHYSICAL_TOTAL_LAND_AREA_SF.get(obj);
                let plate = Field.IMPROVEMENT_MAIN_FLOOR_PLATE_AREA.get(obj);

                return (total && plate) ? plate / total * 100 : 0;
            },
            default: "",
            dependsOn: () => [Field.PHYSICAL_TOTAL_LAND_AREA_SF, Field.IMPROVEMENT_MAIN_FLOOR_PLATE_AREA]
        });

    static IMPROVEMENT_FLOOR_SPACE_RATIO = new Field(
        "improvement.floor_space_ratio", {
            [FieldLabel.DEFAULT]: "Floor Space Ratio",
            [FieldLabel.SHORT]: "FSR",
            [FieldLabel.REPORT_HEADER]: "FSR"
        }, {
            type: FieldType.PERCENT,
            numDecimals: 1,
            derive: obj => {
                let gross = Field.IMPROVEMENT_GROSS_BUILDING_AREA.get(obj);
                let total = Field.PHYSICAL_TOTAL_LAND_AREA_SF.get(obj);
                return (gross && total) ? gross / total * 100 : 0;
            },
            dependsOn: () => [Field.IMPROVEMENT_GROSS_BUILDING_AREA, Field.PHYSICAL_TOTAL_LAND_AREA_SF]
        });

    static IMPROVEMENT_FLOOR_SPACE_INDEX = new Field("improvement.floor_space_ratio", "Floor Space Index");

    static IMPROVEMENT_YEAR_BUILT = new Field(
        "improvement.year_built", "Year Built");

    static IMPROVEMENT_NUM_BEDROOMS = new Field(
        "improvement.num_bedrooms", {
            [FieldLabel.DEFAULT]: "Number of Bedrooms",
            [FieldLabel.SHORT]: "Bedrooms",
        }, {
            // values: [1,2,3,4,5,6],
            indexed: true
        });

    static IMPROVEMENT_NUM_RENOVATED_UNITS = new Field(
        "improvement.num_renovated_units", "Number of Renovated Units", {type: FieldType.INTEGER});

    static IMPROVEMENT_NUM_BATHROOMS = new Field(
        "improvement.num_bathrooms", {
            [FieldLabel.DEFAULT]: "Number of Bathrooms",
            [FieldLabel.SHORT]: "Bathrooms",
        }, {
            values: [1,1.5,2,2.5,3,3.5,4,4.5,5],
            indexed: true
        });

    static IMPROVEMENT_HAS_ELEVATOR = new Field(
      "improvement.has_elevator", {
          [FieldLabel.DEFAULT]: "Elevator",
      }, {
          type: FieldType.BOOLEAN,
      });

    static IMPROVEMENT_FEATURES = new Field(
        "improvement.features", "Features", {
            values: ["Deck", "Patio", "Air Conditioning", "Dishwasher"],
            choice: true
        });

    static IMPROVEMENT_AMENITIES = new Field(
      "improvement.amenities", "Building Amenities", {
          type: FieldType.STRING,
      });

    static IMPROVEMENT_OUTBUILDING_COMMENTS = new Field(
        "improvement.outbuilding_comments", "Outbuilding Comments", {
            type: FieldType.STRING,
        });
    static IMPROVEMENT_OUTBUILDING_CONTRIBUTORY_VALUE = new Field(
        "improvement.outbuilding_contrib_value", "Contributory Value of Outbuildings", {
            type: FieldType.CURRENCY,
        });

    static IMPROVEMENT_DWELLING_COMMENTS = new Field(
        "improvement.dwelling_comments", "Dwelling(s) Comments", {
            type: FieldType.STRING,
        });
    static IMPROVEMENT_DWELLING_CONTRIBUTORY_VALUE = new Field(
        "improvement.dwelling_contrib_value", "Contributory Value of Dwelling(s)", {
            type: FieldType.CURRENCY,
        });

    static PHYSICAL_CORNER_LOT = new Field("physical.corner_lot", "Corner Lot", {type: FieldType.BOOLEAN});
    static PHYSICAL_EXISTING_USE = new Field("physical.existing_use", "Existing Use", {
        default: (comp) => {
            switch(comp.type) {
                case MULTI_FAMILY:
                case VACANT_LAND:
                    return propertyNameTitle(comp.type);
                default:
                    return null;
            }
        }
    });
    static PHYSICAL_FRONTAGE = new Field("physical.frontage", {
        [FieldLabel.DEFAULT]: o => `Frontage - ${FieldOptions.METRIC.is(o) ? "Metres" : "Feet"}`,
        [FieldLabel.SHORT]: o => `Frontage (${FieldOptions.METRIC.is(o) ? "m" : "ft"})`
    }, {
        type: FieldType.NUMBER,
        unit: Unit.FT,
        toMetric: Unit.FT_TO_METRE
    });

    static PHYSICAL_MOORAGE = new Field("physical.moorage", {
        [FieldLabel.DEFAULT]: o => `Moorage - ${FieldOptions.METRIC.is(o) ? "Metres" : "Feet"}`,
        [FieldLabel.SHORT]: o => `Moorage (${FieldOptions.METRIC.is(o) ? "m" : "ft"})`
    }, {
        type: FieldType.NUMBER,
        unit: Unit.FT,
        toMetric: Unit.FT_TO_METRE
    });

    static PHYSICAL_DEPTH = new Field("physical.depth", {
        [FieldLabel.DEFAULT]: o => `Depth - ${FieldOptions.METRIC.is(o) ? "Metres" : "Feet"}`,
        [FieldLabel.SHORT]: o => `Depth (${FieldOptions.METRIC.is(o) ? "m" : "ft"})`
    }, {
        type: FieldType.NUMBER,
        unit: Unit.FT,
        toMetric: Unit.FT_TO_METRE
    });

    static PHYSICAL_FRONTAGE_STREET = new Field("physical.frontage_street", "Frontage Street");

    static PHYSICAL_IN_FLOOD_PLAIN = new Field("physical.in_flood_plain", {
        [FieldLabel.DEFAULT]: "Subject In Flood Plain?",
        [FieldLabel.SHORT]: "In Flood Plain",
    }, {
        values: ["Yes", "No", "Partially"]
    });
    static PHYSICAL_IN_ALR = new Field("physical.in_alr", {
        [FieldLabel.DEFAULT]: "Subject In ALR?",
        [FieldLabel.SHORT]: "In ALR",
    }, {
        values: ["Yes", "No", "Partially"]
    });
    static PHYSICAL_LAND_AREA_UNITS = new Field("physical.land_area_units", "Land Area Units", {
        values: comp => comp.type === ICI || comp.type === MULTI_FAMILY ?
            [AreaUnit.FT2.label, AreaUnit.Acres.label, AreaUnit.None.label]: [AreaUnit.FT2.label, AreaUnit.Acres.label],
        default: AreaUnit.FT2.label
    });

    static PHYSICAL_PROPERTY_TYPE = new Field("physical.property_type", "Property Type", {
        values: comp => {
            switch(comp.type) {
                case ICI: return [
                    PhysicalPropertyType.RETAIL_OFFICE,
                    PhysicalPropertyType.INDUSTRIAL,
                    PhysicalPropertyType.MIXED_USE,
                    PhysicalPropertyType.INSTITUTIONAL,
                    PhysicalPropertyType.FARM,
                    PhysicalPropertyType.OTHER_COMMERCIAL
                ];
                case MULTI_FAMILY: return [
                    "Low-Rise Apartment",
                    "Mid-rise Apartment",
                    "High-rise Apartment",
                    "Duplex",
                    "Triplex",
                    "4-Plex",
                    "Townhouse Development",
                    PhysicalPropertyType.RESIDENTIAL
                ];
                case VACANT_LAND: return [
                    PhysicalPropertyType.AGRICULTURAL_FARMLAND,
                    "Commercial",
                    "Comprehensive Development",
                    "Industrial",
                    "Institutional",
                    "Multi-Family",
                    "Open Space",
                    PhysicalPropertyType.RESIDENTIAL_DEV_LANDS,
                    "Residential",
                    "Resource"
                ];
                case LEASE: return [
                    PhysicalPropertyType.OFFICE,
                    PhysicalPropertyType.RETAIL,
                    PhysicalPropertyType.OFFICE_RETAIL,
                    PhysicalPropertyType.INDUSTRIAL,
                    PhysicalPropertyType.VACANT_LAND,
                    PhysicalPropertyType.COMMERCIAL,
                    PhysicalPropertyType.COMMERCIAL_RESIDENTIAL,
                    PhysicalPropertyType.MIXED_USE,
                    PhysicalPropertyType.INSTITUTIONAL
                ]
                case RESIDENTIAL_RENT: return [
                    "House (full)",
                    "House (main floor)",
                    "House (basement suite)",
                    "Half Duplex",
                    "Triplex",
                    "Fourplex",
                    "Townhouse",
                    "Apartment",
                    "Manufactured Home",
                    "Full House with Acreage",
                    "Storage",
                    "Cabin",
                    "Loft",
                    "Mixed Commercial/Residential",
                    "Rental Suite (non-basement)"
                ];
                default:
                    return [];
            }
        },
        indexed: true
    });


    static PHYSICAL_PROPERTY_SUBTYPE = new Field("physical.property_subtype", "Property Sub-Type", {
        values: comp => {
            const c = comp.type || ICI;
            const t = Field.PHYSICAL_PROPERTY_TYPE.get(comp) || "";
            if (t === PhysicalPropertyType.RETAIL_OFFICE) {
                return [
                    "Converted Office",
                    PhysicalPropertySubType.DENTAL_OFFICE,
                    PhysicalPropertySubType.MEDICAL_OFFICE,
                    "Office Building",
                    "Office Unit (Strata)",
                    PhysicalPropertySubType.PROFESSIONAL_OFFICE,
                    "Retail Building",
                    "Retail Unit (Strata)",
                    "Shopping Mall",
                    "Strip Mall",
                    "Retail Plaza"
                ];
            }
            else if (t === PhysicalPropertyType.INDUSTRIAL) {
                return c === LEASE ? [
                    "Cement Plant",
                    "Concrete Mixing Plant",
                    "Logging Operations",
                    "Manufacturing",
                    "Mining",
                    "Oil Refining Plant",
                    "Plaza",
                    "Pulp & Paper Mill",
                    "Sawmill",
                    "Strata Unit (Industrial)",
                    PhysicalPropertySubType.WAREHOUSE,
                    PhysicalPropertySubType.WAREHOUSE_OFFICE,
                ] : [
                    "Brewery",
                    "Cement Plant",
                    "Concrete Mixing Plant",
                    "Garage/Shop",
                    "Logging Operations",
                    "Manufacturing",
                    "Mining",
                    "Oil Refining Plant",
                    "Parking Lot (Industrial)",
                    "Pulp & Paper Mill",
                    "Sawmill",
                    "Strata Unit (Industrial)",
                    "Vacant Land (Industrial)",
                    PhysicalPropertySubType.WAREHOUSE,
                    PhysicalPropertySubType.WAREHOUSE_OFFICE,
                ]
            }
            else if (t === PhysicalPropertyType.MIXED_USE) {
                return [
                    "High-Rise",
                    "Low-Rise"
                ]
            }
            else if (t === PhysicalPropertyType.INSTITUTIONAL) {
                return c === LEASE ? [
                    "Cemeteries",
                    "Church",
                    "Daycare",
                    "Government Building",
                    "Hospital",
                    "Landfill",
                    "Park/Playing Field",
                    "Recreational Building",
                    "Research Centre",
                    "School/University"
                ] : [
                    "Campground",
                    "Cemeteries",
                    "Church",
                    "Community Hall",
                    "Daycare",
                    PhysicalPropertySubType.GOLF_COURSE,
                    "Government Building",
                    "Hospital",
                    "Landfill",
                    "Mixed-Use",
                    "Park/Playing Field",
                    "Recreational Building",
                    "Research Centre",
                    "School/University",
                    "Ski Hill",
                    "Vacant Land",
                    "Works Yards"
                ]
            }
            else if (t === PhysicalPropertyType.FARM) {
                return [
                    "Beef",
                    "Dairy",
                    "Fruits",
                    "Grain & Forage",
                    "Multi-Purpose",
                    "Poultry",
                    "Vacant Land (Farm)",
                    "Vegetable"
                ]
            }
            else if (t === PhysicalPropertyType.OTHER_COMMERCIAL) {
                return [
                    "Airport",
                    "Air Space Parcel",
                    "Automobile Dealership",
                    "Bank",
                    "Billboard",
                    "Bowling Alley",
                    "Cafe",
                    "Campground",
                    PhysicalPropertySubType.CAR_WASH,
                    "Department Store",
                    "Fast Food",
                    PhysicalPropertySubType.GOLF_COURSE,
                    "Greenhouses & Nurseries",
                    "Grocery Store",
                    PhysicalPropertySubType.HOTEL,
                    PhysicalPropertySubType.MARINE_FACILITIES,
                    PhysicalPropertySubType.MOBILE_HOME_PARK,
                    PhysicalPropertySubType.MOTEL,
                    "Neighbourhood Pub",
                    "Parking Garage",
                    "Recreational",
                    "Resort",
                    "Restaurant",
                    PhysicalPropertySubType.SERVICE_STATION,
                    "Storage",
                    "Theatre",
                    "Water Lot (IC&I)",
                    "Winery",
                    null,
                    "Other"
                ]
            }
            else if (t === PhysicalPropertyType.OFFICE) {
                const isLease = c === LEASE;
                return [
                    "Converted Office",
                    ...(isLease ? [PhysicalPropertySubType.DENTAL_OFFICE] : null),
                    ...(isLease ? [PhysicalPropertySubType.MEDICAL_OFFICE] : null),
                    "Office Building",
                    "Office Unit (Strata)",
                    ...(isLease ? [PhysicalPropertySubType.PROFESSIONAL_OFFICE] : null),
                ]
            }
            else if (t === PhysicalPropertyType.RETAIL) {
                return [
                    "Retail Building",
                    "Retail Unit (Strata)",
                    "Shopping Mall",
                    "Strip Mall"
                ]
            }
            else if (t === PhysicalPropertyType.OFFICE_RETAIL) {
                return [
                    "Converted Office",
                    "Office Building",
                    "Office Unit (Strata)",
                    "Retail Building",
                    "Retail Unit (Strata)",
                    "Shopping Mall",
                    "Strip Mall"
                ]
            }
            else if (t === PhysicalPropertyType.VACANT_LAND) {
                return [
                    "Works Yards",
                    "Farm",
                    "Parking Lot",
                    "Storage Yard",
                    "Agricultural",
                    "Water Lot"
                ]
            }
            else if (t === PhysicalPropertyType.COMMERCIAL) {
                return [
                    "Automotive",
                    "Airport",
                    "Air Space Parcel",
                    "Automobile Dealership",
                    "Bank",
                    "Billboard",
                    "Bowling Alley",
                    "Brewery",
                    "Campground",
                    "Cemetery",
                    "Church",
                    "Car Wash",
                    "Daycare",
                    "Department Store",
                    "Fast Food",
                    "Greenhouses & Nurseries",
                    "Golf Course",
                    "Grocery Store",
                    "Hotel",
                    "Landfill",
                    "Marine Facilities",
                    PhysicalPropertySubType.MOBILE_HOME_PARK,
                    "Motel",
                    "Neighbourhood Pub",
                    "Parking Garage",
                    "Pharmacy",
                    "Recreational",
                    "Resort",
                    "Restaurant",
                    "Service Station",
                    "Ski Hill",
                    "Storage",
                    "Theatre"
                ]
            }
            else if (t === PhysicalPropertyType.RESIDENTIAL) {
                return [
                    PhysicalPropertySubType.SINGLE_FAMILY_DWELLING
                ]
            }
            else return [];
        },
        indexed: true
    });

    static PHYSICAL_PROPOSED_USE = new Field("physical.proposed_use", "Proposed Use");
    static PHYSICAL_SHAPE = new Field("physical.shape", "Shape", {
        values: ["Rectangular", "Square", "Triangular", "Irregular", "L-Shaped"]
    });
    static PHYSICAL_TOPOGRAPHY = new Field("physical.topography", "Topography", {
        values: [
            "Level",
            "Gently sloping",
            "Moderately sloping",
            "Steep sloping",
            "Flat",
            "Flat / gently undulating",
            "Gently undulating",
            "Gently undulating / rolling",
            "Rolling",
            "Rolling / hilly",
            "Hilly",
            "Mixed",
        ]
    });
    static PHYSICAL_TOTAL_LAND_AREA = new Field("physical.total_land_area", "Total Land Area", {
        type: FieldType.AREA,
        unit: comp => {
            let u = Field.PHYSICAL_LAND_AREA_UNITS.get(comp);
            return AreaUnit.get(u);
        },
        indexed: true
    });

    static PHYSICAL_WATER_LOT_LEASE_AREA = new Field("physical.water_lot_lease_area", "Water Lot Lease Area", {
        type: FieldType.AREA,
        unit: AreaUnit.FT2
    });
    static PHYSICAL_FORESHORE_LEASE_AREA = new Field("physical.foreshore_lease_area", "Foreshore Lease Area", {
        type: FieldType.AREA,
        unit: AreaUnit.FT2
    });

    static PHYSICAL_ACCESS = new Field("physical.access", "Access", {
        values: ["Very Good", "Good", "Average", "Below Average", "Poor"]
    });
    static PHYSICAL_EXPOSURE = new Field("physical.exposure", "Exposure", {
        values: ["Very Good", "Good", "Average", "Below Average", "Poor"]
    });

    static PHYSICAL_TOTAL_LAND_AREA_ACRE = new Field("physical.total_land_area_acre", {
        [FieldLabel.DEFAULT]: o => `Total Land Area - ${FieldOptions.METRIC.is(o)?"Square Metres":"Acres"}`,
        [FieldLabel.SHORT]: o => `Total Land Area (${FieldOptions.METRIC.is(o)?"SQM":"AC"})`,
        [FieldLabel.REPORT_HEADER]: o => `Total Land Area (${FieldOptions.METRIC.is(o)?"SQM":"Acres"})`,
    }, {
        type: FieldType.NUMBER,
        derive: comp => {
            let a = Field.PHYSICAL_TOTAL_LAND_AREA.get(comp);
            let u = Field.PHYSICAL_LAND_AREA_UNITS.get(comp);
            if (a && u) {
                let unit = AreaUnit.get(u);
                switch(unit) {
                    case AreaUnit.None:
                        return null;
                    case AreaUnit.FT2:
                        return a * unit.factor;
                    default:
                        return a;

                }
            }
        },
        numDecimals: 3,
        toMetric: AreaUnit.ACRE_TO_M2,
        dependsOn: () => [Field.PHYSICAL_TOTAL_LAND_AREA, Field.PHYSICAL_LAND_AREA_UNITS]
    });

    static PHYSICAL_TOTAL_LAND_AREA_SF = new Field("physical.total_land_area_sf", {
        [FieldLabel.DEFAULT]: o => `Total Land Area - ${FieldOptions.METRIC.is(o)?"Square Metres":"Square Feet"}`,
        [FieldLabel.SHORT]: o => `Total Land Area (${FieldOptions.METRIC.is(o)?"SQM":"SQFT"})`
    }, {
        type: FieldType.NUMBER,
        derive: comp => {
            let a = Field.PHYSICAL_TOTAL_LAND_AREA.get(comp);
            let u = Field.PHYSICAL_LAND_AREA_UNITS.get(comp);
            if (a && u) {
                let unit = AreaUnit.get(u);
                switch(unit) {
                    case AreaUnit.None:
                        return null;
                    case AreaUnit.Acres:
                        return a * unit.factor;
                    default:
                        return a;

                }
            }
        },
        toMetric: AreaUnit.FT2_TO_M2,
        dependsOn: () => [Field.PHYSICAL_TOTAL_LAND_AREA, Field.PHYSICAL_LAND_AREA_UNITS]
    });

    static PHYSICAL_EFFECTIVE_LAND_AREA = new Field("physical.effective_land_area", "Effective/Usable Land Area", {
        type: FieldType.AREA,
        unit: comp => {
            let u = Field.PHYSICAL_LAND_AREA_UNITS.get(comp);
            return AreaUnit.get(u);
        }
    });
    static PHYSICAL_EFFECTIVE_LAND_AREA_ACRE = new Field("physical.effective_land_area_acre", {
        [FieldLabel.DEFAULT]: o => `Effective Land Area - ${FieldOptions.METRIC.is(o)?"Square Metres":"Acres"}`,
        [FieldLabel.SHORT]: o => `Effective Land Area (${FieldOptions.METRIC.is(o)?"SQM":"AC"})`,
        [FieldLabel.REPORT_HEADER]: o => `Effective Land Area (${FieldOptions.METRIC.is(o)?"SQM":"Acres"})`
    }, {
        type: FieldType.NUMBER,
        derive: comp => {
            let a = Field.PHYSICAL_EFFECTIVE_LAND_AREA.get(comp);
            let u = Field.PHYSICAL_LAND_AREA_UNITS.get(comp);
            if (a && u) {
                let unit = AreaUnit.get(u);
                switch(unit) {
                    case AreaUnit.None:
                        return null;
                    case AreaUnit.FT2:
                        return a * unit.factor;
                    default:
                        return a;

                }
            }
        },
        toMetric: AreaUnit.ACRE_TO_M2,
        dependsOn: () => [Field.PHYSICAL_EFFECTIVE_LAND_AREA, Field.PHYSICAL_LAND_AREA_UNITS]
    });

    static PHYSICAL_EFFECTIVE_LAND_AREA_SF = new Field("physical.effective_land_area_sf", {
        [FieldLabel.DEFAULT]: o => `Effective Land Area - ${FieldOptions.METRIC.is(o)?"Square Metres":"Square Feet"}`,
        [FieldLabel.SHORT]: o => `Effective Land Area (${FieldOptions.METRIC.is(o)?"SQM":"SQFT"})`,
        [FieldLabel.REPORT_HEADER]: o => `Effective Land Area (${FieldOptions.METRIC.is(o)?"SQM":"SQFT"})`
    }, {
        type: FieldType.NUMBER,
        derive: comp => {
            let a = Field.PHYSICAL_EFFECTIVE_LAND_AREA.get(comp);
            let u = Field.PHYSICAL_LAND_AREA_UNITS.get(comp);
            if (a && u) {
                let unit = AreaUnit.get(u);
                switch(unit) {
                    case AreaUnit.None:
                        return null;
                    case AreaUnit.Acres:
                        return a * unit.factor;
                    default:
                        return a;

                }
            }
        },
        toMetric: AreaUnit.FT2_TO_M2,
        dependsOn: () => [Field.PHYSICAL_EFFECTIVE_LAND_AREA, Field.PHYSICAL_LAND_AREA_UNITS]
    });

    static PHYSICAL_TOTAL_BUILDABLE_AREA = new Field("physical.total_buildable_area", {
        [FieldLabel.DEFAULT]: `Total Buildable Area`,
    }, {
        type: FieldType.AREA,
        unit: comp => {
            let u = Field.PHYSICAL_LAND_AREA_UNITS.get(comp);
            return AreaUnit.get(u);
        }
    });
    static PHYSICAL_BUILDING_SITE_AREA = new Field("physical.building_site_area", {
        [FieldLabel.DEFAULT]: `Building Site Area`,
    }, {
        type: FieldType.AREA,
        unit: comp => {
            let u = Field.PHYSICAL_LAND_AREA_UNITS.get(comp);
            return AreaUnit.get(u);
        },
        numDecimals: 2
    });

    static PHYSICAL_TOTAL_BUILDABLE_AREA_ACRE = new Field("physical.total_buildable_area_acre", {
        [FieldLabel.DEFAULT]: o => `Total Buildable Area - ${FieldOptions.METRIC.is(o)?"Square Metres":"Acres"}`,
        [FieldLabel.SHORT]: o => `Total Buildable Area (${FieldOptions.METRIC.is(o)?"SQM":"AC"})`,
        [FieldLabel.REPORT_HEADER]: o => `Total Buildable Area (${FieldOptions.METRIC.is(o)?"SQM":"Acres"})`
    }, {
        type: FieldType.NUMBER,
        derive: comp => {
            let a = Field.PHYSICAL_TOTAL_BUILDABLE_AREA.get(comp);
            let u = Field.PHYSICAL_LAND_AREA_UNITS.get(comp);
            if (a && u) {
                let unit = AreaUnit.get(u);
                switch(unit) {
                    case AreaUnit.None:
                        return null;
                    case AreaUnit.FT2:
                        return a * unit.factor;
                    default:
                        return a;

                }
            }
        },
        toMetric: AreaUnit.ACRE_TO_M2,
        dependsOn: () => [Field.PHYSICAL_TOTAL_BUILDABLE_AREA, Field.PHYSICAL_LAND_AREA_UNITS]
    });

    static PHYSICAL_TOTAL_BUILDABLE_AREA_SF = new Field("physical.total_buildable_area_qf", {
        [FieldLabel.DEFAULT]: o => `Total Buildable Area - ${FieldOptions.METRIC.is(o)?"Square Metres":"Square Feet"}`,
        [FieldLabel.SHORT]: o => `Total Buildable Area (${FieldOptions.METRIC.is(o)?"SQM":"SQFT"})`,
        [FieldLabel.REPORT_HEADER]: o => `Total Buildable Area (${FieldOptions.METRIC.is(o)?"SQM":"SQFT"})`
    }, {
        type: FieldType.NUMBER,
        derive: comp => {
            let a = Field.PHYSICAL_TOTAL_BUILDABLE_AREA.get(comp);
            let u = Field.PHYSICAL_LAND_AREA_UNITS.get(comp);
            if (a && u) {
                let unit = AreaUnit.get(u);
                switch(unit) {
                    case AreaUnit.None:
                        return null;
                    case AreaUnit.Acres:
                        return a * unit.factor;
                    default:
                        return a;

                }
            }
        },
        toMetric: AreaUnit.FT2_TO_M2,
        dependsOn: () => [Field.PHYSICAL_TOTAL_BUILDABLE_AREA, Field.PHYSICAL_LAND_AREA_UNITS]
    });

    static PHYSICAL_BUILDABLE_AREA_PERCENT_SF = new Field("physical.buildable_area_percent_sf", {
        [FieldLabel.DEFAULT]: o => `Percentage of Buildable Area (${FieldOptions.METRIC.is(o)?"Square Metres":"Square Feet"})`,
        [FieldLabel.SHORT]: o => `% of Buildable Area (${FieldOptions.METRIC.is(o)?"SQM":"SQFT"})`,
    }, {
        type: FieldType.PERCENT,
        derive: (comp) => {
            const buildable = Field.PHYSICAL_TOTAL_BUILDABLE_AREA_SF.get(comp);
            const total = Field.PHYSICAL_TOTAL_LAND_AREA_SF.get(comp);
            if (total && buildable) {
                return buildable / total * 100;
            }
            return 0;
        },
        toMetric: AreaUnit.FT2_TO_M2,
        dependsOn: () => [Field.PHYSICAL_TOTAL_BUILDABLE_AREA_SF, Field.PHYSICAL_TOTAL_LAND_AREA_SF]
    });

    static PHYSICAL_BUILDABLE_AREA_PERCENT_ACRE = new Field("physical.buildable_area_percent_acre", {
        [FieldLabel.DEFAULT]: o => `Percentage of Buildable Area (${FieldOptions.METRIC.is(o)?"Square Metres":"Acres"})`,
        [FieldLabel.SHORT]: o => `% of Buildable Area (${FieldOptions.METRIC.is(o)?"SQM":"AC"})`,
    }, {
        type: FieldType.PERCENT,
        derive: (comp) => {
            const buildable = Field.PHYSICAL_TOTAL_BUILDABLE_AREA_ACRE.get(comp);
            const total = Field.PHYSICAL_TOTAL_LAND_AREA_ACRE.get(comp);
            if (total && buildable) {
                return buildable / total * 100;
            }
            return 0;
        },
        toMetric: AreaUnit.ACRE_TO_M2,
        dependsOn: () => [Field.PHYSICAL_TOTAL_BUILDABLE_AREA_ACRE, Field.PHYSICAL_TOTAL_LAND_AREA_ACRE]
    });

    static PHYSICAL_ARABLE_AREA_PERCENT = new Field("physical.arable_area_percent", {
        [FieldLabel.DEFAULT]: `Percentage of Arable Area`,
        [FieldLabel.SHORT]: `% of Arable Area`,
    }, {
        type: FieldType.PERCENT,
    });

    static PHYSICAL_ARABLE_AREA = new Field("physical.arable_area", {
        [FieldLabel.DEFAULT]: o => `Arable Area`,
        [FieldLabel.SHORT]: o => `Arable Area`
    }, {
        type: FieldType.NUMBER,
        formatWithComma: true,
        numDecimals: 2,
        unit: comp => {
            let u = Field.PHYSICAL_LAND_AREA_UNITS.get(comp);
            return AreaUnit.get(u);
        },
        derive: (comp) => {
            const total = Field.PHYSICAL_TOTAL_LAND_AREA.get(comp);
            const percent = Field.PHYSICAL_ARABLE_AREA_PERCENT.get(comp);
            if (total && percent) {
                return total * (percent/100);
            }

            return 0;
        },
        dependsOn: () => [Field.PHYSICAL_TOTAL_LAND_AREA, Field.PHYSICAL_ARABLE_AREA_PERCENT]
    });

    static PHYSICAL_ZONING_CODE = new Field("physical.zoning_code", "Zoning Code");
    static PHYSICAL_ZONING_DESC = new Field("physical.zoning_desc", {
        [FieldLabel.DEFAULT]: "Zoning Description",
        [FieldLabel.SHORT]: "Zoning Desc."
    });
    static PHYSICAL_OCP_DESIGNATION = new Field("physical.ocp_designation", {
        [FieldLabel.DEFAULT]: "OCP/OP Designation"
    });

    static PHYSICAL_DEVELOPMENT_TYPE = new Field("physical.development_type", {
        [FieldLabel.DEFAULT]: "Development Type",
    }, {
        values: [PhysicalDevelopmentType.SINGLE_FAMILY, PhysicalDevelopmentType.LOW_DENSITY, PhysicalDevelopmentType.MEDIUM_DENSITY, PhysicalDevelopmentType.HIGH_DENSITY]
    });

    static PHYSICAL_NUM_DEVELOPABLE_LOTS = new Field("physical.num_developable_lots", {
        [FieldLabel.DEFAULT]: "Number of Developable Lots",
        [FieldLabel.SHORT]: "# Dev. Lots",
    }, {
        type: FieldType.INTEGER
    });
    static PHYSICAL_NUM_DEVELOPABLE_UNITS = new Field("physical.num_developable_units", {
        [FieldLabel.DEFAULT]: "Number of Developable Units",
        [FieldLabel.SHORT]: "# Dev. Units",
    }, {
        type: FieldType.INTEGER
    });
    static PHYSICAL_SOIL_TYPE = new Field("physical.soil_type", {
        [FieldLabel.DEFAULT]: "Soil Type",
    }, );
    static PHYSICAL_CLI_CLASS = new Field("physical.cli_class", {
        [FieldLabel.DEFAULT]: "CLI Class",
    }, {
        // type: FieldType.INTEGER,
        values: ["1", "2", "3", "4", "5", "6", "7", "0"],
        multiple: true,
    });
    static PHYSICAL_DRAINAGE = new Field("physical.drainage", "Drainage", {
        values: [
            "Untiled",
            "Random Tiled",
            "Systematically Tiled",
            "Partial Untiled/Random Tiled",
            "Partial Untiled/Systematically Tiled",
            "Partial Untiled/Random/Systematically Tiled",
            "Partial Random/Systematically Tiled"
        ]
    });

    static PHYSICAL_GREENBELT_CONSERVATION_AUTHORITY = new Field("physical.greenbelt_conservation_authority",
      "Greenbelt/Conservation Authority"
    );
    static PHYSICAL_INTERIM_USE = new Field("physical.interim_use", "Interim Use");

    static PHYSICAL_DEVELOPMENT_TIMEFRAME = new Field("physical.development_timeframe",
      "Development Timeframe"
    );
    static PHYSICAL_PLANNING_STATUS = new Field("physical.planning_status",
      "Planning Status"
    );
    static PHYSICAL_OPEN_SPACE_ESA = new Field("physical.open_space_esa",
      "Open Space/ESA"
    );
    static PHYSICAL_DRAFT_PLAN_STATUS = new Field("physical.draft_plan_status",
      "Draft Plan Status"
    );
    static PHYSICAL_NUM_PROPOSED_APPROVED_UNITS = new Field("physical.num_proposed_approved_units", {
      [FieldLabel.DEFAULT]: "Number of Proposed/Approved Units",
      [FieldLabel.SHORT]: "# Prop/Apvd Units"
    }, {
        type: FieldType.INTEGER
    });
    static PHYSICAL_APPLICATION_NUM = new Field("physical.application_num",
      "Application Number"
    );
    static PHYSICAL_SITE_DIMENSIONS = new Field("physical.site_dimensions",
        "Site Dimensions"
    );
    static PHYSICAL_WATERFRONT = new Field("physical.waterfront",
        "Waterfront", {
            values: ['Yes', 'No', 'Partial']
        }
    );
    static PHYSICAL_OTHER_SITE_AREA = new Field("physical.other_site_area", {
        [FieldLabel.DEFAULT]: `Other Site Area`,
    }, {
        type: FieldType.AREA,
        derive: comp => {
            const total = Field.PHYSICAL_TOTAL_LAND_AREA.get(comp);
            if (total) {
                const arable = Field.PHYSICAL_ARABLE_AREA.get(comp) || 0;
                const building = Field.PHYSICAL_BUILDING_SITE_AREA.get(comp) || 0;
                return total - arable - building;
            }
            return null;
        },
        dependsOn: () => [Field.PHYSICAL_TOTAL_LAND_AREA, Field.PHYSICAL_ARABLE_AREA, Field.PHYSICAL_BUILDING_SITE_AREA],
        unit: comp => {
            let u = Field.PHYSICAL_LAND_AREA_UNITS.get(comp);
            return AreaUnit.get(u);
        },
        numDecimals: 2
    });
    static SALES_USE_EFFECTIVE_SALE_PRICE = new Field("sales.use_effective_sale_price",
        "Use Effective Sale Price", {type: FieldType.BOOLEAN});

    static SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE = new Field("sales.sale_price_or_effective_sale_price",
        "Sale Price or Effective Sale Price", {
            type: FieldType.CURRENCY,
            derive: obj => {
                const useEffective = Field.SALES_USE_EFFECTIVE_SALE_PRICE.get(obj);
                return useEffective === true ? Field.SALES_EFFECTIVE_PRICE.get(obj) : Field.SALES_PRICE.get(obj)
            },
            dependsOn: () => [Field.SALES_USE_EFFECTIVE_SALE_PRICE, Field.SALES_EFFECTIVE_PRICE, Field.SALES_PRICE]
        });

    static SALES_CONDITIONS = new Field("sales.conditions", "Conditions of Sale", {
        values: ["Arm's Length", "Non Arm's Length"]
    });

    static SALES_DAYS_ON_MARKET = new Field("sales.days_on_market", "Days on Market", {type: FieldType.INTEGER});
    static SALES_PRICE = new Field("sales.price", "Sale Price", {type: FieldType.CURRENCY, indexed: true});
    static SALES_PRICE_PER_SQFT = new Field("sales.price_per_sqft", {
        [FieldLabel.DEFAULT]: o => `Sale Price per ${FieldOptions.METRIC.is(o)?"Square Metre":"Square Foot"} - Rentable Area`,
        [FieldLabel.SHORT]: o => `Price / ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"} Rentable Area`,
        [FieldLabel.REPORT_HEADER]: o => `Sale Price / ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"} - Rentable Area`,
    }, {
        type: FieldType.CURRENCY,
        roundToDollar: false,
        derive: obj => {
            const price = Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            if (price) {
                const area = Field.IMPROVEMENT_RENTABLE_AREA.get(obj);
                if (area) {
                    return price / area;
                }
            }
            return 0;
        },
        toMetric: 1/AreaUnit.FT2_TO_M2,
        dependsOn: () => [Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.IMPROVEMENT_RENTABLE_AREA]
    });
    static SALES_PRICE_PER_SQFT_WITHOUT_BASEMENT = new Field("sales.price_per_sqft_without_basement", {
        [FieldLabel.DEFAULT]: o => `Sale Price per ${FieldOptions.METRIC.is(o)?"Square Metre":"Square Foot"} - Gross Building Area excluding Basement`,
        [FieldLabel.SHORT]: o => `Price / ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"} Gross Building Area (w/o Bsmt)`,
        [FieldLabel.REPORT_HEADER]: o => `Sale Price / ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"} - Gross Building Area w/o Bsmt`,
    }, {
        type: FieldType.CURRENCY,
        roundToDollar: false,
        derive: obj => {
            const price = Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            if (price) {
                const gross = Field.IMPROVEMENT_GROSS_BUILDING_AREA.get(obj);
                if (gross) {
                    return price / gross;
                }
            }
            return 0;
        },
        toMetric: 1/AreaUnit.FT2_TO_M2,
        dependsOn: () => [Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.IMPROVEMENT_GROSS_BUILDING_AREA]
    });
    static SALES_PRICE_PER_SQFT_WITH_BASEMENT = new Field("sales.price_per_sqft_with_basement", {
        [FieldLabel.DEFAULT]: o => `Sale Price per ${FieldOptions.METRIC.is(o)?"Square Metre":"Square Foot"} - Gross Building Area including Basement`,
        [FieldLabel.SHORT]: o => `Price / ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"} - Gross Building Area w Bsmt`,
        [FieldLabel.REPORT_HEADER]: o => `Sale Price / ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"} - Gross Building Area w Bsmt`,
    }, {
        type: FieldType.CURRENCY,
        roundToDollar: false,
        derive: obj => {
            const price = Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            if (price) {
                const gross = Field.IMPROVEMENT_GROSS_BUILDING_AREA.get(obj);
                const bmnt = Field.IMPROVEMENT_BASEMENT_AREA.get(obj) || 0;
                if (gross) {
                    return price / (gross + bmnt);
                }
            }
            return 0;
        },
        toMetric: 1/AreaUnit.FT2_TO_M2,
        dependsOn: () => [Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.IMPROVEMENT_GROSS_BUILDING_AREA, Field.IMPROVEMENT_BASEMENT_AREA]
    });

    static SALES_PRICE_ADJUSTMENT = new Field("sales.price_adjust", "Adjustments to Sale Price", {type: FieldType.CURRENCY});
    static SALES_EXPENDITURES_POST_SALE = new Field("sales.expenditures_post_sale", "Expenditures Made After Sale", {type: FieldType.CURRENCY});

    static SALES_CONTRIBUTORY_IMPROVEMENTS = new Field("sales.contributory_improvements", "Contributory Value of Improvements", {
        type: FieldType.CURRENCY,
        negative: true,
        shouldDerive: obj => {
            // only derive if there is not a value set
            return get(obj, Field.SALES_CONTRIBUTORY_IMPROVEMENTS.path) == null;
        },
        derive: obj => {
            const o = Field.IMPROVEMENT_OUTBUILDING_CONTRIBUTORY_VALUE.get(obj) || 0;
            const d = Field.IMPROVEMENT_DWELLING_CONTRIBUTORY_VALUE.get(obj) || 0;
            if (o || d) {
                return o + d;
            }
            return null;
        },
        dependsOn: () => [Field.IMPROVEMENT_OUTBUILDING_CONTRIBUTORY_VALUE, Field.IMPROVEMENT_DWELLING_CONTRIBUTORY_VALUE]
    });
    static SALES_DEMOLITION_COSTS = new Field("sales.demolition_costs", "Estimated Demolition Costs", {
        type: FieldType.CURRENCY
    });

    static SALES_EFFECTIVE_PRICE = new Field("sales.effective_price", "Effective Sale Price", {
        type: FieldType.CURRENCY,
        derive: obj => {
            let price = Field.SALES_PRICE.get(obj);
            if (price) {
                let adjust = Field.SALES_PRICE_ADJUSTMENT.get(obj);
                if (adjust) {
                    price += adjust;
                }

                let improvements = Field.SALES_CONTRIBUTORY_IMPROVEMENTS.get(obj);
                if (improvements) {
                    price -= improvements;
                }

                let expend = Field.SALES_EXPENDITURES_POST_SALE.get(obj);
                if (expend) {
                    price += expend;
                }

                let demo = Field.SALES_DEMOLITION_COSTS.get(obj);
                if (demo) {
                    price += demo;
                }
                return price;
            }
            return 0;
        },
        dependsOn: () => [Field.SALES_PRICE, Field.SALES_PRICE_ADJUSTMENT, Field.SALES_EXPENDITURES_POST_SALE]
    });

    static SALES_PROPERTY_RIGHTS = new Field("sales.property_rights", "Property Rights Conveyed", {
        values: ["Fee Simple", "Leased Fee", "Strata", "Leasehold", "Easement"]
    });

    static SALES_DATE = new Field("sales.date", "Sale Date", {type: FieldType.DATE, indexed: true});
    static SALES_CLOSING_DATE = new Field("sales.closing_date", "Sale Closing Date", {type: FieldType.DATE});

    static SALES_STATUS = new Field("sales.status", "Sale Status", {
        values: ["Sale", "Under Contract", "Current Listing"]
    });
    static SALES_BUYER = new Field("sales.buyer", "Buyer");
    static SALES_SELLER = new Field("sales.seller", "Seller");
    static SALES_FINANCING_DESCRIPTION = new Field("sales.financing_description", "Financing Description", {
        type: FieldType.TEXT
    });

    static SALES_PRICE_PER_SF_TOTAL = new Field("sales.price_per_sf_total", {
        [FieldLabel.DEFAULT]: o => `Sale Price per ${FieldOptions.METRIC.is(o)?"Square Metre":"Square Foot"} - Total Area`,
        [FieldLabel.SHORT]: o => `Price / ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"} Total Area`,
        [FieldLabel.REPORT_HEADER]: o => `Sale Price per ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"} - Total Area`
    }, {
        type: FieldType.CURRENCY,
        roundToDollar: false,
        derive: obj => {
            const price = Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            if (price) {
                const area = Field.PHYSICAL_TOTAL_LAND_AREA_SF.get(obj);
                if (area) {
                    return price / area;
                }
            }
            return 0;
        },
        toMetric: 1/AreaUnit.FT2_TO_M2,
        indexed: true,
        dependsOn: () => [Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.PHYSICAL_TOTAL_LAND_AREA_SF]
    });
    static SALES_PRICE_PER_SF_EFFECTIVE = new Field("sales.price_per_sf_effective", {
        [FieldLabel.DEFAULT]: o => `Sale Price per Square ${FieldOptions.METRIC.is(o)?"Metre":"Foot"} - Effective Area`,
        [FieldLabel.SHORT]: o => `Price / ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"} Effective Area`,
        [FieldLabel.REPORT_HEADER]: o => `Sale Price per ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"} - Effective Area`,
    }, {
        type: FieldType.CURRENCY,
        roundToDollar: false,
        derive: obj => {
            const price = Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            if (price) {
                const area = Field.PHYSICAL_EFFECTIVE_LAND_AREA_SF.get(obj);
                if (area) {
                    return price / area;
                }
            }
            return 0;
        },
        toMetric: 1/AreaUnit.FT2_TO_M2,
        dependsOn: () => [Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.PHYSICAL_EFFECTIVE_LAND_AREA_SF]
    });
    static SALES_PRICE_PER_ACRE_TOTAL = new Field("sales.price_per_acre_total", {
        [FieldLabel.DEFAULT]: o => `Sale Price per ${FieldOptions.METRIC.is(o)?"Square Metre":"Acre"} - Total Area`,
        [FieldLabel.SHORT]: o => `Price / ${FieldOptions.METRIC.is(o)?"SQM":"AC"} Total Area`,
        [FieldLabel.REPORT_HEADER]: o => `Sale Price / ${FieldOptions.METRIC.is(o)?"SQM":"Acre"} - Total Area`,
    }, {
        type: FieldType.CURRENCY,
        derive: obj => {
            const price = Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            if (price) {
                const area = Field.PHYSICAL_TOTAL_LAND_AREA_ACRE.get(obj);
                if (area) {
                    return price / area;
                }
            }
            return 0;
        },
        toMetric: 1/AreaUnit.ACRE_TO_M2,
        indexed: true,
        dependsOn: () => [Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.PHYSICAL_TOTAL_LAND_AREA_ACRE]
    });
    static SALES_PRICE_PER_ACRE_EFFECTIVE = new Field("sales.price_per_acre_effective", {
        [FieldLabel.DEFAULT]: o => `Sale Price per ${FieldOptions.METRIC.is(o)?"Square Metre":"Acre"} - Effective Area`,
        [FieldLabel.SHORT]: o => `Price / ${FieldOptions.METRIC.is(o)?"SQM":"AC"} Effective Area`,
        [FieldLabel.REPORT_HEADER]: o => `Sale Price / ${FieldOptions.METRIC.is(o)?"SQM":"Acre"} - Effective Area`,
    }, {
        type: FieldType.CURRENCY,
        derive: obj => {
            const price = Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            if (price) {
                const area = Field.PHYSICAL_EFFECTIVE_LAND_AREA_ACRE.get(obj);
                if (area) {
                    return price / area;
                }
            }
            return 0;
        },
        toMetric: 1/AreaUnit.ACRE_TO_M2,
        dependsOn: () => [Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.PHYSICAL_EFFECTIVE_LAND_AREA_ACRE]
    });
    static SALES_EFFECTIVE_PRICE_PER_ACRE_TOTAL = new Field("sales.effective_price_per_acre_total", {
        [FieldLabel.DEFAULT]: o => `Effective Sale Price per ${FieldOptions.METRIC.is(o)?"Square Metre":"Acre"} - Total Area`,
        [FieldLabel.SHORT]: o => `Effective Price / ${FieldOptions.METRIC.is(o)?"SQM":"AC"} Total Area`,
        [FieldLabel.REPORT_HEADER]: o => `Effective Sale Price / ${FieldOptions.METRIC.is(o)?"SQM":"Acre"} - Total Area`,
    }, {
        type: FieldType.CURRENCY,
        derive: obj => {
            const price = Field.SALES_EFFECTIVE_PRICE.get(obj);
            if (price) {
                const area = Field.PHYSICAL_TOTAL_LAND_AREA_ACRE.get(obj);
                if (area) {
                    return price / area;
                }
            }
            return 0;
        },
        toMetric: 1/AreaUnit.ACRE_TO_M2,
        indexed: true,
        dependsOn: () => [Field.SALES_EFFECTIVE_PRICE, Field.PHYSICAL_TOTAL_LAND_AREA_ACRE]
    });

    static SALES_PRICE_PER_UNIT = new Field("sales.price_per_unit", {
        [FieldLabel.DEFAULT]: "Sale Price per Unit",
        [FieldLabel.SHORT]: "Price / Unit",
        [FieldLabel.REPORT_HEADER]: "Sale Price per Unit"
    }, {
        type: FieldType.CURRENCY,
        derive: obj => {
            const price = Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            if (price) {
                const units = Field.IMPROVEMENT_NUM_UNITS.get(obj);
                if (units) {
                    return price / units;
                }
            }
            return 0;
        },
        indexed: true,
        dependsOn: () => [Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.IMPROVEMENT_NUM_UNITS]
    });

    static SALES_PRICE_PER_PAD = new Field("sales.price_per_pad", {
        [FieldLabel.DEFAULT]: "Sale Price per Pad",
        [FieldLabel.SHORT]: "Price / Pad",
        [FieldLabel.REPORT_HEADER]: "Sale Price per Pad"
    }, {
        type: FieldType.CURRENCY,
        derive: obj => {
            const price = Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            if (price) {
                const pads = Field.IMPROVEMENT_NUM_PADS.get(obj);
                if (pads) {
                    return price / pads;
                }
            }
            return 0;
        },
        indexed: true,
        dependsOn: () => [Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.IMPROVEMENT_NUM_PADS]
    });

    static SALES_PRICE_PER_HOLE = new Field("sales.price_per_hole", {
        [FieldLabel.DEFAULT]: "Sale Price per Hole",
        [FieldLabel.SHORT]: "Price / Hole",
        [FieldLabel.REPORT_HEADER]: "Sale Price per Hole"
    }, {
        type: FieldType.CURRENCY,
        derive: obj => {
            const price = Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            if (price) {
                const holes = Field.IMPROVEMENT_NUM_HOLES.get(obj);
                if (holes) {
                    return price / holes;
                }
            }
            return 0;
        },
        indexed: true,
        dependsOn: () => [Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.IMPROVEMENT_NUM_HOLES]
    });

    static SALES_PRICE_PER_SF_BUILDING_AREA = new Field("sales.price_per_sf_building_area", {
        [FieldLabel.DEFAULT]: o => `Sale Price per Square ${FieldOptions.METRIC.is(o)?"Metre":"Foot"} - Buildable Area`,
        [FieldLabel.SHORT]: o => `Price / ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"} Buildable Area`,
        [FieldLabel.REPORT_HEADER]: o => `Sale Price per ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"} - Buildable Area`,
    }, {
        type: FieldType.CURRENCY,
        derive: obj => {
            const price = Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            if (price) {
                const area = Field.PHYSICAL_TOTAL_BUILDABLE_AREA_SF.get(obj);
                if (area) {
                    return price / area;
                }
            }
            return 0;
        },
        toMetric: 1/AreaUnit.FT2_TO_M2,
        indexed: true,
        dependsOn: () => [Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.PHYSICAL_TOTAL_BUILDABLE_AREA_SF]
    });

    static SALES_PRICE_PER_ACRE_BUILDING_AREA = new Field("sales.price_per_acre_building_area", {
        [FieldLabel.DEFAULT]: o => `Sale Price per ${FieldOptions.METRIC.is(o)?"Square Metre":"Acre"} - Buildable Area`,
        [FieldLabel.SHORT]: o => `Price / ${FieldOptions.METRIC.is(o)?"SQM":"AC"} Buildable Area`,
        [FieldLabel.REPORT_HEADER]: o => `Sale Price per ${FieldOptions.METRIC.is(o)?"SQM":"AC"} - Buildable Area`,
    }, {
        type: FieldType.CURRENCY,
        derive: obj => {
            const price = Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            if (price) {
                const area = Field.PHYSICAL_TOTAL_BUILDABLE_AREA_ACRE.get(obj);
                if (area) {
                    return price / area;
                }
            }
            return 0;
        },
        toMetric: 1/AreaUnit.FT2_TO_M2,
        indexed: true,
        dependsOn: () => [Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.PHYSICAL_TOTAL_BUILDABLE_AREA_ACRE]
    });

    static SALES_PRICE_PER_FRONTAGE = new Field("sales.price_per_frontage", {
        [FieldLabel.DEFAULT]: o => `Sale Price per Frontage ${FieldOptions.METRIC.is(o)?"Metre":"Foot"}`,
        [FieldLabel.SHORT]: o => `Price / Frontage ${FieldOptions.METRIC.is(o)?"Metre":"Ft"}`,
    }, {
        type: FieldType.CURRENCY,
        derive: obj => {
            const price = Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            if (price) {
                const front = Field.PHYSICAL_FRONTAGE.get(obj);
                if (front) {
                    return price / front;
                }
            }
            return 0;
        },
        toMetric: 1/AreaUnit.FT2_TO_M2,
        dependsOn: () => [Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.PHYSICAL_FRONTAGE]
    });

    static SALES_PRICE_PER_MOORAGE = new Field("sales.price_per_moorage", {
        [FieldLabel.DEFAULT]: o => `Sale Price per ${FieldOptions.METRIC.is(o)?"Metre":"Foot"} of Moorage`,
        [FieldLabel.SHORT]: o => `Price / Moorage ${FieldOptions.METRIC.is(o)?"Metre":"Ft"}`,
    }, {
        type: FieldType.CURRENCY,
        derive: obj => {
            const price = Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            if (price) {
                const moorage = Field.PHYSICAL_MOORAGE.get(obj);
                if (moorage) {
                    return price / moorage;
                }
            }
            return 0;
        },
        toMetric: 1/AreaUnit.FT2_TO_M2,
        dependsOn: () => [Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.PHYSICAL_MOORAGE]
    });

    static SALES_PRICE_PER_MAIN_FLOOR_PLATE_AREA = new Field("sales.price_per_main_floor_plate_area", {
        [FieldLabel.DEFAULT]: o => `Sale Price per Square ${FieldOptions.METRIC.is(o)?"Metre":"Foot"} - Main Floor Plate`,
        [FieldLabel.SHORT]: o => `Price / ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"} Main Floor Plate`,
    }, {
        type: FieldType.CURRENCY,
        derive: obj => {
            const price = Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            if (price) {
                const mainFloorPlate = Field.IMPROVEMENT_MAIN_FLOOR_PLATE_AREA.get(obj);
                if (mainFloorPlate) {
                    return price / mainFloorPlate;
                }
            }
            return 0;
        },
        toMetric: 1/AreaUnit.FT2_TO_M2,
        dependsOn: () => [Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.PHYSICAL_MOORAGE]
    });

    static SALES_PRICE_PER_ARABLE_AREA = new Field("sales.price_per_arable_area", {
        [FieldLabel.DEFAULT]: o => `Sale Price per Arable Area`,
        [FieldLabel.SHORT]: o => `Price / Arable Area`,
    }, {
        type: FieldType.CURRENCY,
        derive: obj => {
            const price = Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            if (price) {
                const arableArea = Field.PHYSICAL_ARABLE_AREA.get(obj);
                if (arableArea) {
                    return price / arableArea;
                }
            }
            return 0;
        },
        toMetric: 1/AreaUnit.ACRE_TO_M2,
        dependsOn: () => [Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.PHYSICAL_ARABLE_AREA]
    });

    static SALES_EFFECTIVE_PRICE_PER_ARABLE_AREA = new Field("sales.effective_price_per_arable_area", {
        [FieldLabel.DEFAULT]: o => `Effective Sale Price per Arable Area`,
        [FieldLabel.SHORT]: o => `Effective Price / Arable Area`,
    }, {
        type: FieldType.CURRENCY,
        derive: obj => {
            const price = Field.SALES_EFFECTIVE_PRICE.get(obj);
            if (price) {
                const arableArea = Field.PHYSICAL_ARABLE_AREA.get(obj);
                if (arableArea) {
                    return price / arableArea;
                }
            }
            return 0;
        },
        toMetric: 1/AreaUnit.ACRE_TO_M2,
        dependsOn: () => [Field.SALES_EFFECTIVE_PRICE, Field.PHYSICAL_ARABLE_AREA]
    });

    static SALES_DEVELOPMENT_COST_CHARGES= new Field("sales.development_cost_charges", {
        [FieldLabel.DEFAULT]: "Development Cost Charges",
        [FieldLabel.SHORT]: "DCC",
    }, {
        type: FieldType.CURRENCY
    });

    static SALES_DENSITY_PER_ACRE_TOTAL = new Field("sales.density_per_acre_total", {
        [FieldLabel.DEFAULT]: "Density per Acre - Total Area",
        [FieldLabel.SHORT]: "Density ",
        [FieldLabel.DEFAULT]: o => `Density per ${FieldOptions.METRIC.is(o)?"Square Metre":"Acre"} - Total Area`,
        [FieldLabel.SHORT]: o => `Density / ${FieldOptions.METRIC.is(o)?"SQM":"AC"} Total Area`,
        [FieldLabel.REPORT_HEADER]: o => `Density / ${FieldOptions.METRIC.is(o)?"SQM":"Acre"} - Total Area`,
    }, {
        type: FieldType.NUMBER,
        derive: comp => {
            const numUnits = Field.PHYSICAL_NUM_DEVELOPABLE_UNITS.get(comp);
            if (numUnits) {
                const area = Field.PHYSICAL_TOTAL_LAND_AREA_ACRE.get(comp);
                if (area) {
                    return numUnits / area;
                }
            }
            return 0;
        },
        toMetric: 1/AreaUnit.ACRE_TO_M2
    });

    static SALES_DENSITY_PER_ACRE_EFFECTIVE = new Field("sales.density_per_acre_effective", {
        [FieldLabel.DEFAULT]: "Density per Acre - Effective Area",
        [FieldLabel.SHORT]: "Density ",
        [FieldLabel.DEFAULT]: o => `Density per ${FieldOptions.METRIC.is(o)?"Square Metre":"Acre"} - Effective Area`,
        [FieldLabel.SHORT]: o => `Density / ${FieldOptions.METRIC.is(o)?"SQM":"AC"} Effective Area`,
        [FieldLabel.REPORT_HEADER]: o => `Density / ${FieldOptions.METRIC.is(o)?"SQM":"Acre"} - Effective Area`,
    }, {
        type: FieldType.NUMBER,
        derive: comp => {
            const numUnits = Field.PHYSICAL_NUM_DEVELOPABLE_UNITS.get(comp);
            if (numUnits) {
                const area = Field.PHYSICAL_EFFECTIVE_LAND_AREA_ACRE.get(comp);
                if (area) {
                    return numUnits / area;
                }
            }
            return 0;
        },
        toMetric: 1/AreaUnit.ACRE_TO_M2
    });

    static SALES_PRICE_PER_DEVELOPABLE = new Field("sales.price_per_dev", {
        [FieldLabel.DEFAULT]: o => `Sale Price per Developable ${PhysicalDevelopmentType.isSingleFamily(o)?"Lot":"Unit"}`,
        [FieldLabel.SHORT]: o => `Price / Dev. ${PhysicalDevelopmentType.isSingleFamily(o)?"Lot":"Unit"}`,
        [FieldLabel.REPORT_HEADER]: o => `Sale Price per Dev. ${PhysicalDevelopmentType.isSingleFamily(o)?"Lot":"Unit"}`
    }, {
        type: FieldType.CURRENCY,
        derive: obj => {
            const price = Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            if (price) {
                const unitsOrLots = PhysicalDevelopmentType.isSingleFamily(obj)
                    ? Field.PHYSICAL_NUM_DEVELOPABLE_LOTS.get(obj) : Field.PHYSICAL_NUM_DEVELOPABLE_UNITS.get(obj);
                if (unitsOrLots) {
                    return price / unitsOrLots;
                }
            }
            return 0;
        },
        dependsOn: () => [Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.PHYSICAL_NUM_DEVELOPABLE_LOTS, Field.PHYSICAL_NUM_DEVELOPABLE_UNITS]
    });

    static SALES_PRICE_PER_PROPOSED_GROSS_FLOOR_AREA = new Field("sales.price_per_proposed_gross_floor_area", {
        [FieldLabel.DEFAULT]: o => `Sale Price per Proposed Gross Floor Area`,
        [FieldLabel.SHORT]: o => `Price / Proposed GFA`,
    }, {
        type: FieldType.CURRENCY,
        derive: obj => {
            const price = Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            if (price) {
                const area = Field.IMPROVEMENT_TOTAL_PROPOSED_GROSS_FLOOR_AREA.get(obj);
                if (area) {
                    return price / area;
                }
            }
            return 0;
        },
        toMetric: 1/AreaUnit.ACRE_TO_M2,
        dependsOn: () => [Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.IMPROVEMENT_TOTAL_PROPOSED_GROSS_FLOOR_AREA]
    });

    static SALES_IS_RESALE = new Field("sales.is_resale", "ReSale?", {
        type: FieldType.BOOLEAN
    });

    static SALES_FUEL_SALES = new Field("sales.fuel_sales", {
        [FieldLabel.DEFAULT]: o => "Annual Fuel Sales",
        [FieldLabel.SHORT]: o => "Fuel Sales",
    }, {
        type: FieldType.INTEGER,
        unit: Unit.LITRES
    });

    static PRICE_PER_LITRE_FORMATTER = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 4,
        minimumFractionDigits: 4
    });

    static SALES_PRICE_PER_LITRE = new Field("sales.price_per_litre", {
        [FieldLabel.DEFAULT]: o => "Sale Price per Litre",
        [FieldLabel.SHORT]: o => `Price / L`,
    }, {
        type: FieldType.CURRENCY,
        derive: obj => {
            const price = Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            const litres = Field.SALES_FUEL_SALES.get(obj);
            if (price && litres) {
                return price / litres;
            }
            return 0;
        },
        dependsOn: () => [Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.SALES_FUEL_SALES],
        numDecimals: 4,
        formatCurrency: v => Field.PRICE_PER_LITRE_FORMATTER.format(v)
    });

    static SALES_FUEL_MARGIN_PER_LITRE = new Field("sales.fuel_margin_per_litre", {
        [FieldLabel.DEFAULT]: o => "Fuel Margin per Litre",
        [FieldLabel.SHORT]: o => `Fuel Margin / L`,
    }, {
        type: FieldType.CURRENCY,
        numDecimals: 4,
        formatCurrency: v => Field.PRICE_PER_LITRE_FORMATTER.format(v)
    });

    static SALES_CROSS_LEASE_PER_LITRE = new Field("sales.cross_lease_per_litre", {
        [FieldLabel.DEFAULT]: o => "Cross-Lease",
        [FieldLabel.REPORT_HEADER]: o => `Cross-Lease ($/L)`,
    }, {
        type: FieldType.CURRENCY,
        unit: "$/Litre",
        numDecimals: 4,
        formatCurrency: v => Field.PRICE_PER_LITRE_FORMATTER.format(v)
    });

    static SALES_COMBINED_FUEL_MARGIN = new Field("sales.combined_fuel_margin", {
        [FieldLabel.DEFAULT]: o => "Combined Fuel Margin",
    }, {
        derive: obj => {
            const margin = Field.SALES_FUEL_MARGIN_PER_LITRE.get(obj) || 0;
            const crossLease = Field.SALES_CROSS_LEASE_PER_LITRE.get(obj) || 0;
            return margin + crossLease;
        },
        dependsOn: () => [Field.SALES_FUEL_MARGIN_PER_LITRE, Field.SALES_CROSS_LEASE_PER_LITRE],
        type: FieldType.CURRENCY,
        numDecimals: 4,
        formatCurrency: v => Field.PRICE_PER_LITRE_FORMATTER.format(v)
    });

    static SALES_SERVICE_STATION_BRAND = new Field("sales.service_station_brand", {
        [FieldLabel.DEFAULT]: o => "Service Station Brand",
        [FieldLabel.SHORT]: o => "Brand",
    });

    static SALES_PRICE_PER_BAY = new Field("sales.price_per_bay", {
        [FieldLabel.DEFAULT]: o => "Sale Price per Bay",
        [FieldLabel.SHORT]: o => `Price / Bay`,
    }, {
        type: FieldType.CURRENCY,
        derive: obj => {
            const price = Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            const litres = Field.IMPROVEMENT_NUM_BAYS.get(obj);
            if (price && litres) {
                return price / litres;
            }
            return 0;
        },
        dependsOn: () => [Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.IMPROVEMENT_NUM_BAYS]
    });

    static SALES_PRICE_PER_ROOM = new Field("sales.price_per_room", {
        [FieldLabel.DEFAULT]: o => "Sale Price per Room",
        [FieldLabel.SHORT]: o => `Price / Room`,
    }, {
        type: FieldType.CURRENCY,
        derive: obj => {
            const price = Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            const rooms = Field.IMPROVEMENT_NUM_ROOMS.get(obj);
            if (price && rooms) {
                return price / rooms;
            }
            return 0;
        },
        dependsOn: () => [Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.IMPROVEMENT_NUM_ROOMS]
    });

    static SALES_PRICE_PER_BEDROOM = new Field("sales.price_per_bedroom", {
        [FieldLabel.DEFAULT]: o => "Sale Price per Bedroom",
        [FieldLabel.SHORT]: o => `Price / Bedroom`,
    }, {
        type: FieldType.CURRENCY,
        derive: obj => {
            const price = Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            const rooms = Field.IMPROVEMENT_NUM_BEDROOMS.get(obj);
            if (price && rooms) {
                return price / rooms;
            }
            return 0;
        },
        dependsOn: () => [Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.IMPROVEMENT_NUM_BEDROOMS]
    });

    static SALES_CONVENIENCE_STORE_SALES = new Field("sales.convenience_store_sales", {
        [FieldLabel.DEFAULT]: o => "Convenience Store Sales",
        [FieldLabel.SHORT]: o => "Convenience Sales",
    }, {
        type: FieldType.CURRENCY
    });

    static SALES_CONVENIENCE_STORE_MARGIN = new Field("sales.convenience_store_margin", {
        [FieldLabel.DEFAULT]: o => "Convenience Store Margin",
        [FieldLabel.SHORT]: o => "Convenience Margin",
    }, {
        type: FieldType.PERCENT
    });

    static SALES_CONVENIENCE_STORE_SALES_AND_MARGIN = new Field("sales.convenience_store_sales_and_margin", {
        [FieldLabel.DEFAULT]: o => "Convenience Store Sales / Margin"
    }, {
        type: FieldType.STRING,
        derive: obj => {
            const sales = Field.SALES_CONVENIENCE_STORE_SALES.get(obj);
            const margin = Field.SALES_CONVENIENCE_STORE_MARGIN.get(obj);
            if (sales && margin) {
                return `${Field.SALES_CONVENIENCE_STORE_SALES.render(sales)} / ${Field.SALES_CONVENIENCE_STORE_MARGIN.render(margin)}`;
            }
            return null;
        },
        dependsOn: () => [Field.SALES_CONVENIENCE_STORE_SALES, Field.SALES_CONVENIENCE_STORE_MARGIN]
    });

    static SALES_CAR_WASH_SALES = new Field("sales.car_wash_sales", {
        [FieldLabel.DEFAULT]: o => "Car Wash Sales"
    }, {
        type: FieldType.CURRENCY
    });

    static SALES_CAR_WASH_MARGIN = new Field("sales.car_wash_margin", {
        [FieldLabel.DEFAULT]: o => "Car Wash Margin"
    }, {
        type: FieldType.PERCENT
    });

    static SALES_CAR_WASH_SALES_AND_MARGIN = new Field("sales.car_wash_sales_and_margin", {
        [FieldLabel.DEFAULT]: o => "Car Wash Sales / Margin"
    }, {
        type: FieldType.STRING,
        derive: obj => {
            const sales = Field.SALES_CAR_WASH_SALES.get(obj);
            const margin = Field.SALES_CAR_WASH_MARGIN.get(obj);
            if (sales && margin) {
                return `${Field.SALES_CAR_WASH_SALES.render(sales)} / ${Field.SALES_CAR_WASH_MARGIN.render(margin)}`;
            }
            return null;
        },
        dependsOn: () => [Field.SALES_CAR_WASH_SALES, Field.SALES_CAR_WASH_MARGIN]
    });

    static SALES_FAST_FOOD_SALES = new Field("sales.fast_food_sales", {
        [FieldLabel.DEFAULT]: o => "Fast Food Sales"
    }, {
        type: FieldType.CURRENCY
    });

    static SALES_FAST_FOOD_MARGIN = new Field("sales.fast_food_margin", {
        [FieldLabel.DEFAULT]: o => "Fast Food Margin"
    }, {
        type: FieldType.PERCENT
    });

    static SALES_FAST_FOOD_SALES_AND_MARGIN = new Field("sales.fast_food_sales_and_margin", {
        [FieldLabel.DEFAULT]: o => "Fast Food Sales / Margin"
    }, {
        type: FieldType.STRING,
        derive: obj => {
            const sales = Field.SALES_FAST_FOOD_SALES.get(obj);
            const margin = Field.SALES_FAST_FOOD_MARGIN.get(obj);
            if (sales && margin) {
                return `${Field.SALES_FAST_FOOD_SALES.render(sales)} / ${Field.SALES_FAST_FOOD_MARGIN.render(margin)}`;
            }
            return null;
        },
        dependsOn: () => [Field.SALES_FAST_FOOD_SALES, Field.SALES_FAST_FOOD_MARGIN]
    });

    static SALES_ATM_SALES = new Field("sales.atm_sales", {
        [FieldLabel.DEFAULT]: o => "ATM Sales"
    }, {
        type: FieldType.CURRENCY
    });

    static SALES_ATM_MARGIN = new Field("sales.atm_margin", {
        [FieldLabel.DEFAULT]: o => "ATM Margin"
    }, {
        type: FieldType.PERCENT
    });

    static SALES_ATM_SALES_AND_MARGIN = new Field("sales.atm_sales_and_margin", {
        [FieldLabel.DEFAULT]: o => "ATM Sales / Margin"
    }, {
        type: FieldType.STRING,
        derive: obj => {
            const sales = Field.SALES_ATM_SALES.render(obj);
            const margin = Field.SALES_ATM_MARGIN.render(obj);
            if (sales && margin) {
                return `${Field.SALES_ATM_SALES.render(sales)} / ${Field.SALES_ATM_MARGIN.render(margin)}`
            }
            return null;
        },
        dependsOn: () => [Field.SALES_ATM_SALES, Field.SALES_ATM_MARGIN]
    });

    static SALES_OTHER_SALES = new Field("sales.other_sales", {
        [FieldLabel.DEFAULT]: o => "Other Sales"
    }, {
        type: FieldType.CURRENCY
    });

    static SALES_OTHER_MARGIN = new Field("sales.other_margin", {
        [FieldLabel.DEFAULT]: o => "Other Margin"
    }, {
        type: FieldType.PERCENT
    });

    static SALES_OTHER_SALES_AND_MARGIN = new Field("sales.other_sales_and_margin", {
        [FieldLabel.DEFAULT]: o => "Other Sales / Margin"
    }, {
        type: FieldType.STRING,
        derive: obj => {
            const sales = Field.SALES_OTHER_SALES.get(obj);
            const margin = Field.SALES_OTHER_MARGIN.get(obj);
            if (sales && margin) {
                return `${Field.SALES_OTHER_SALES.render(sales)} / ${Field.SALES_OTHER_MARGIN.render(margin)}`;
            }
            return null;
        },
        dependsOn: () => [Field.SALES_OTHER_SALES, Field.SALES_OTHER_MARGIN]
    });

    static SALES_IMPROVEMENT_CONTRIBUTORY_VALUE = new Field("sales.improvement_contrib_value", {
        [FieldLabel.DEFAULT]: o => "Contributory Value of Improvements"
    }, {
        type: FieldType.CURRENCY,
        derive: obj => {
            const o = Field.IMPROVEMENT_OUTBUILDING_CONTRIBUTORY_VALUE.get(obj) || 0;
            const d = Field.IMPROVEMENT_DWELLINGS_CONTRIBUTORY_VALUE.get(obj) || 0;
            return o + d;
        },
        dependsOn: () => [Field.IMPROVEMENT_OUTBUILDING_CONTRIBUTORY_VALUE, Field.IMPROVEMENT_DWELLINGS_CONTRIBUTORY_VALUE]
    });

    static UTILITIES_HYDRO = new Field("utilities.hydro", "Hydro", {
        values: ["Yes", "No", "Available"]
    });
    // static UTILITIES_HYDRO_OTHER = new Field("utilities.hydro_other", {
    //     [FieldLabel.DEFAULT]: "Hydro Source",
    //     [FieldLabel.FORM]: "Specify Hydro Source"
    // });

    static UTILITIES_NATURAL_GAS = new Field("utilities.natural_gas", "Natural Gas", {
        values: ["Yes", "No", "Available"]
    });
    // static UTILITIES_NATURAL_GAS_OTHER = new Field("utilities.natural_gas_other", {
    //     [FieldLabel.DEFAULT]: "Natural Gas Source",
    //     [FieldLabel.FORM]: "Specify Natural Gas Source"
    // });

    static UTILITIES_SEWER = new Field("utilities.sewer", "Sewer", {
        values: ["Municipal", "Septic", "Other", "None"]
    });
    static UTILITIES_SEWER_OTHER = new Field("utilities.water_other", {
        [FieldLabel.DEFAULT]: "Sweer Source",
        [FieldLabel.FORM]: "Specify Sewer Source"
    });

    static UTILITIES_WATER = new Field("utilities.water", "Water", {
        values: ["Municipal", "Community", "Well (private)", "Well (shared)", "Lakewater Intake", "Other", "None"]
    });
    static UTILITIES_WATER_OTHER = new Field("utilities.water_other", {
        [FieldLabel.DEFAULT]: "Water Source",
        [FieldLabel.FORM]: "Specify Water Source"
    });

    static UTILITIES_DESCRIPTION = new Field("utilities.description", "Utilities Description");

    static FINANCIAL_USE_EFFECTIVE_SALE_PRICE = new Field("financial.use_effective_sale_price",
        "Use Effective Sale Price", {type: FieldType.BOOLEAN});

    static FINANCIAL_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE = new Field("financial.sale_price_or_effective_sale_price",
        "Sale Price or Effective Sale Price", {
        type: FieldType.CURRENCY,
        derive: obj => {
            const useEffective = Field.FINANCIAL_USE_EFFECTIVE_SALE_PRICE.get(obj);
            return useEffective === true ? Field.SALES_EFFECTIVE_PRICE.get(obj) : Field.SALES_PRICE.get(obj)
        },
        dependsOn: () => [Field.FINANCIAL_USE_EFFECTIVE_SALE_PRICE, Field.SALES_EFFECTIVE_PRICE, Field.SALES_PRICE]
    });

    static FINANCIAL_RENTABLE_AREA_PRICE = new Field("financial.rentable_area_price",
        {
            [FieldLabel.DEFAULT]: o => `Rentable Area Price per Square ${FieldOptions.METRIC.is(o)?"Metre":"Foot"}`,
            [FieldLabel.SHORT]: o => `Rentable Area ($/${FieldOptions.METRIC.is(o)?"SQM":"SQFT"})`,
            [FieldLabel.REPORT_HEADER]: o => `Rentable Area Price per ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"}`,
        }, {
        type: FieldType.CURRENCY,
        derive: obj => {
            let price = Field.FINANCIAL_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            if (price) {
                let area = Field.IMPROVEMENT_RENTABLE_AREA.get(obj);
                if (area) {
                    return price / area;
                }
            }
            return 0;
        },
        toMetric: 1/AreaUnit.FT2_TO_M2,
        dependsOn: () => [Field.FINANCIAL_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.IMPROVEMENT_RENTABLE_AREA]
    });

    static FINANCIAL_GROSS_BUILDABLE_AREA_PRICE = new Field("financial.gross_buildable_area_price",
        {
            [FieldLabel.DEFAULT]: o => `Gross Buildable Area Price per Square ${FieldOptions.METRIC.is(o)?"Metre":"Foot"}`,
            [FieldLabel.SHORT]: o => `Gross Buildable Area Price / ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"}`,
        }, {
            type: FieldType.CURRENCY,
            derive: obj => {
                let price = Field.FINANCIAL_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
                if (price) {
                    let area = Field.IMPROVEMENT_GROSS_BUILDING_AREA.get(obj);
                    if (area) {
                        return price / area;
                    }
                }
                return 0;
            },
            toMetric: 1/AreaUnit.FT2_TO_M2,
            dependsOn: () => [Field.FINANCIAL_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE, Field.IMPROVEMENT_GROSS_BUILDING_AREA]
        });

    static FINANCIAL_POTENTIAL_GROSS_INCOME = new Field("financial.potential_gross_income",
        "Potential Gross Income", {
        type: FieldType.CURRENCY,
        derive: obj => {
            let base = Field.FINANCIAL_BASE_RENT.get(obj) || 0;
            let other = Field.FINANCIAL_TOTAL_OTHER_REVENUE.get(obj) || 0;
            return base + other;
        },
        shouldDerive: obj => {
            return obj.type === MULTI_FAMILY;
        },
        dependsOn: () => [Field.FINANCIAL_BASE_RENT, Field.FINANCIAL_TOTAL_OTHER_REVENUE]
    });

    static FINANCIAL_EFFECTIVE_GROSS_INCOME = new Field("financial.effective_gross_income",
        "Effective Gross Income", {
        type: FieldType.CURRENCY,
        derive: obj => {
            let gross = Field.FINANCIAL_POTENTIAL_GROSS_INCOME.get(obj);
            if (gross) {
                let loss = Field.FINANCIAL_VACANCY_COLLECTION_LOSS_VALUE.get(obj) || 0;
                if (loss) {
                    gross -= loss;
                }
                return gross;
            }
            return 0;
        },
        dependsOn: () => [Field.FINANCIAL_POTENTIAL_GROSS_INCOME, Field.FINANCIAL_VACANCY_COLLECTION_LOSS_VALUE]
    });

    static FINANCIAL_EFFECTIVE_GROSS_INCOME_PER_UNIT = new Field("financial.effective_gross_income_per_unit",
      {
          [FieldLabel.DEFAULT]: o => "Effective Gross Income per Unit",
          [FieldLabel.SHORT]: o => `EGI / Unit`,
          [FieldLabel.REPORT_HEADER]: o => "Effective Gross Income / Unit"
      },
      {
          type: FieldType.CURRENCY,
          derive: obj => {
              let egi = Field.FINANCIAL_EFFECTIVE_GROSS_INCOME.get(obj);
              let numUnits = Field.IMPROVEMENT_NUM_UNITS.get(obj);
              if (egi && numUnits) {
                  return egi / numUnits;
              }
              return 0;
          },
          dependsOn: () => [Field.FINANCIAL_EFFECTIVE_GROSS_INCOME, Field.IMPROVEMENT_NUM_UNITS]
      });

    static FINANCIAL_EFFECTIVE_GROSS_INCOME_PER_UNIT_PER_MONTH = new Field("financial.effective_gross_income_per_unit_per_month",
      {
          [FieldLabel.DEFAULT]: o => "Effective Gross Income per Unit per Month",
          [FieldLabel.SHORT]: o => `EGI / Unit / Month`,
          [FieldLabel.REPORT_HEADER]: o => "Effective Gross Income / Unit / Month"
      },
      {
          type: FieldType.CURRENCY,
          derive: obj => {
              let egiPerUnit = Field.FINANCIAL_EFFECTIVE_GROSS_INCOME_PER_UNIT.get(obj);
              if (egiPerUnit) {
                  return egiPerUnit/12;
              }
              return 0;
          },
          dependsOn: () => [Field.FINANCIAL_EFFECTIVE_GROSS_INCOME_PER_UNIT]
      });

    static FINANCIAL_EXPENSES = new Field("financial.expenses", "Expenses", {
        default: (comp) => {
            switch(comp.type) {
                case MULTI_FAMILY:
                    return [{
                        id: nanoid(),
                        title: 'Management Expense'
                    }, {
                        id: nanoid(),
                        title: 'Realty Taxes'
                    }, {
                        id: nanoid(),
                        title: 'Utilities'
                    }, {
                        id: nanoid(),
                        title: 'Water/Sewer'
                    }, {
                        id: nanoid(),
                        title: 'Repairs & Maintenance',
                    }, {
                        id: nanoid(),
                        title: 'Insurance',
                    }, {
                        id: nanoid(),
                        title: 'Garbage',
                    }];
                default:
                    return [{
                        id: nanoid(),
                        title: 'Management Expense'
                    }, {
                        id: nanoid(),
                        title: 'Structural Allowance'
                    }, {
                        id: nanoid(),
                        title: 'Non-recoverable Miscellaneous'
                    }, {
                        id: nanoid(),
                        title: 'Other'
                    }];
            }

        }
    });
    static FINANCIAL_TOTAL_EXPENSES = new Field("financial.total_expenses",
        "Total Expenses", {type: FieldType.CURRENCY});
    static FINANCIAL_USE_EXPENSE_CALCULATOR = new Field("financial.use_expense_calculator",
        "Expense Calculator", {
        type: FieldType.BOOLEAN,
        extendUpdate: (value, comp) => {
            const expenses = Field.FINANCIAL_EXPENSES.get(comp);
            Field.FINANCIAL_EXPENSES.update(expenses, comp);
        }
    });

    static FINANCIAL_VACANCY_COLLECTION_LOSS_MODE = new Field("financial.vacancy_collection_loss_mode",
        "Vacancy & Collection Loss Mode", {
        values: [VacationCollectionLossMode.PERCENT, VacationCollectionLossMode.VALUE]
    });

    static FINANCIAL_VACANCY_COLLECTION_LOSS = new Field("financial.vacancy_collection_loss",
        "Vacancy & Collection Loss - Percent", {
        type: FieldType.PERCENT,
        numDecimals: 2
    });

    static FINANCIAL_VACANCY_COLLECTION_LOSS_AMOUNT = new Field("financial.vacancy_collection_loss_value",
        "Vacancy & Collection Loss - Amount", { type: FieldType.CURRENCY});

    static FINANCIAL_VACANCY_COLLECTION_LOSS_VALUE = new Field("financial.vacancy_collection_loss_amount",  // not a typo, couldn't change field name for backwards compat reasons
        "Vacancy & Collection Loss", {
        type: FieldType.CURRENCY,
        derive: obj => {
            const mode = Field.FINANCIAL_VACANCY_COLLECTION_LOSS_MODE.get(obj) || VacationCollectionLossMode.PERCENT;
            if (mode === VacationCollectionLossMode.AMOUNT) {
                return Field.FINANCIAL_VACANCY_COLLECTION_LOSS_AMOUNT.get(obj)
            }

            const gross = Field.FINANCIAL_POTENTIAL_GROSS_INCOME.get(obj);
            if (gross) {
                const percent = Field.FINANCIAL_VACANCY_COLLECTION_LOSS.get(obj);
                if (percent) {
                    return gross * percent/100.0;
                }
            }
            return 0;
        },
        dependsOn: () => [Field.FINANCIAL_POTENTIAL_GROSS_INCOME, Field.FINANCIAL_VACANCY_COLLECTION_LOSS_MODE,
            Field.FINANCIAL_VACANCY_COLLECTION_LOSS, Field.FINANCIAL_VACANCY_COLLECTION_LOSS_VALUE]
    });

    static FINANCIAL_NET_OPERATING_INCOME = new Field("financial.net_operating_income",
        "Net Operating Income", {
        type: FieldType.CURRENCY,
        derive: obj => {
            let income = Field.FINANCIAL_EFFECTIVE_GROSS_INCOME.get(obj);
            if (income) {
                let expenses = Field.FINANCIAL_TOTAL_EXPENSES.get(obj) || 0;
                if (expenses) {
                    income -= expenses;
                }
                return income;
            }
            return 0;
        },
        dependsOn: () => [Field.FINANCIAL_EFFECTIVE_GROSS_INCOME, Field.FINANCIAL_TOTAL_EXPENSES]
    });

    static FINANCIAL_NET_OPERATING_INCOME_PER_SQFT = new Field("financial.net_operating_income_per_sqft", {
        [FieldLabel.DEFAULT]: o => `Net Operating Income per Square ${FieldOptions.METRIC.is(o)?"Metre":"Foot"}`,
        [FieldLabel.SHORT]: o => `NOI / ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"}`,
        [FieldLabel.REPORT_HEADER]: o => `Net Operating Income / ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"}`,
    }, {
       type: FieldType.CURRENCY,
       numDecimals: 2,
       derive: obj => {
            const noi = Field.FINANCIAL_NET_OPERATING_INCOME.get(obj);
            const ba = Field.IMPROVEMENT_GROSS_BUILDING_AREA.get(obj);
            if (noi && ba) {
                return noi / ba;
            }
           return 0;
       },
       toMetric: 1/AreaUnit.FT2_TO_M2,
       dependsOn: () => [Field.FINANCIAL_NET_OPERATING_INCOME, Field.IMPROVEMENT_GROSS_BUILDING_AREA]
    });

    static FINANCIAL_NET_OPERATING_INCOME_PER_UNIT = new Field("financial.net_operating_income_per_unit", {
        [FieldLabel.DEFAULT]: o => "Net Operating Income per Unit",
        [FieldLabel.SHORT]: o => `NOI / Unit`,
        [FieldLabel.REPORT_HEADER]: o => "Net Operating Income / Unit",
    }, {
        type: FieldType.CURRENCY,
        numDecimals: 2,
        derive: obj => {
            const noi = Field.FINANCIAL_NET_OPERATING_INCOME.get(obj);
            const nu = Field.IMPROVEMENT_NUM_UNITS.get(obj);
            if (noi && nu) {
                return noi / nu;
            }
            return 0;
        },
        dependsOn: () => [Field.FINANCIAL_NET_OPERATING_INCOME, Field.IMPROVEMENT_NUM_UNITS]
    });

    static FINANCIAL_NET_OPERATING_INCOME_PER_UNIT_PER_MONTH = new Field("financial.net_operating_income_per_unit_per_month", {
        [FieldLabel.DEFAULT]: o => "Net Operating Income per Unit per Month",
        [FieldLabel.SHORT]: o => `NOI / Unit / Month`,
        [FieldLabel.REPORT_HEADER]: o => "Net Operating Income / Unit / Month",
    }, {
        type: FieldType.CURRENCY,
        numDecimals: 2,
        derive: obj => {
            const noiPerUnit = Field.FINANCIAL_NET_OPERATING_INCOME_PER_UNIT.get(obj);
            return noiPerUnit / 12;
        },
        dependsOn: () => [Field.FINANCIAL_NET_OPERATING_INCOME_PER_UNIT]
    });

    static  FINANCIAL_POTENTIAL_GROSS_INCOME_MULTIPLIER = new Field("financial.potential_gross_income_multiplier",
        {
            [FieldLabel.DEFAULT]: "Potential Gross Income Multiplier (PGIM)",
            [FieldLabel.SHORT]: "PGIM",
            [FieldLabel.REPORT_HEADER]: "Potential GIM",
            [FieldLabel.REPORT_BODY]: "Potential Gross Income Multiplier (PGIM)",
        }, {
        type: FieldType.NUMBER,
        numDecimals: 2,
        derive: obj => {
            const sale = Field.SALES_PRICE.get(obj);
            if (sale) {
                const gross = Field.FINANCIAL_POTENTIAL_GROSS_INCOME.get(obj);
                if (gross) {
                    return sale / gross;
                }
            }
            return 0;
        },
        dependsOn: () => [Field.SALES_PRICE, Field.FINANCIAL_POTENTIAL_GROSS_INCOME]
    });

    static FINANCIAL_EFFECTIVE_GROSS_INCOME_MULTIPLIER = new Field("financial.effective_gross_income_multiplier",
        {
            [FieldLabel.DEFAULT]: "Effective Gross Income Multiplier (EGIM)",
            [FieldLabel.SHORT]: "EGIM",
            [FieldLabel.REPORT_HEADER]: "Effective GIM",
            [FieldLabel.REPORT_BODY]: "Effective Gross Income Multiplier (EGIM)",
        }
    , {
        type: FieldType.NUMBER,
        numDecimals: 2,
        derive: obj => {
            const sale = Field.SALES_PRICE.get(obj);
            if (sale) {
                const eff = Field.FINANCIAL_EFFECTIVE_GROSS_INCOME.get(obj);
                if (eff) {
                    return sale / eff;
                }
            }
            return 0;
        },
        dependsOn: () => [Field.SALES_PRICE, Field.FINANCIAL_EFFECTIVE_GROSS_INCOME]
    });
    static FINANCIAL_CAP_RATE = new Field("financial.cap_rate", {
        [FieldLabel.DEFAULT]: "Capitalization Rate",
        [FieldLabel.SHORT]: "Cap Rate",
        [FieldLabel.REPORT_HEADER]: "Capitalization Rate"
    }, {
        type: FieldType.PERCENT,
        numDecimals: 2,
        derive: obj => {
            let income = Field.FINANCIAL_NET_OPERATING_INCOME.get(obj);
            let price = Field.FINANCIAL_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE.get(obj);
            if (income && price) {
                return (income / price) * 100;
            }
            return 0;
        },
        indexed: true,
        dependsOn: () => [Field.FINANCIAL_NET_OPERATING_INCOME, Field.FINANCIAL_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE]
    });

    static FINANCIAL_EXPENSE_RATIO = new Field("financial.expense_ratio", "Expense Ratio", {
       type: FieldType.PERCENT,
        numDecimals: 2,
        derive: obj => {
            let expenses = Field.FINANCIAL_TOTAL_EXPENSES.get(obj);
            if (expenses) {
                let value = Field.FINANCIAL_POTENTIAL_GROSS_INCOME.get(obj);
                if (value) {
                    return expenses / value * 100;
                }
            }
            return 0;
        },
        dependsOn: () => [Field.FINANCIAL_TOTAL_EXPENSES, Field.FINANCIAL_POTENTIAL_GROSS_INCOME]
    });

    static FINANCIAL_MONTHLY_RENT = new Field("financial.monthly_rent", "Monthly Rent", {
        type: FieldType.CURRENCY, indexed: true
    });
    static FINANCIAL_RENTAL_TERM = new Field("financial.rental_term", "Term of Rent Agreement", {
        type: FieldType.INTEGER,
        unit: "Months"
    });
    static FINANCIAL_RENTAL_START_DATE = new Field("financial.rental_start_date", "Rental Start Date", {
        type: FieldType.DATE,
    });
    static FINANCIAL_UTILITIES_INCLUDED = new Field("financial.utilities_included", {
        [FieldLabel.DEFAULT]: "Utilities Included in Rent",
        [FieldLabel.SHORT]: "Utilities incl. in Rent",
        [FieldLabel.REPORT_HEADER]: "Utilities Included in Rent",
    }, {
        type: FieldType.BOOLEAN
    });
    static FINANCIAL_SEE_ADDITIONAL = new Field("financial.see_additional", "See Additional Financial Indicators", {
        type: FieldType.BOOLEAN
    });
    static FINANCIAL_ANNUAL_EXPENSE_INCREASE = new Field("financial.annual_expense_increase", "Annual Expense Increase", {
        type: FieldType.PERCENT,
        numDecimals: 2,
    });
    static FINANCIAL_DISCOUNT_RATE = new Field("financial.discount_rate", "Discount Rate", {
        type: FieldType.PERCENT,
        numDecimals: 2,
    });
    static FINANCIAL_TERMINAL_CAP_RATE = new Field("financial.terminal_cap_rate", "Terminal Cap Rate", {
        type: FieldType.PERCENT,
        numDecimals: 2,
    });
    static FINANCIAL_YEAR_ONE_CAP_RATE = new Field("financial.year_one_cap_rate", "Year 1 Cap Rate", {
        type: FieldType.PERCENT,
        numDecimals: 2,
    });

    static FINANCIAL_BASE_RENT = new Field("financial.base_rent", "Base Rent", {
        type: FieldType.CURRENCY
    });

    static FINANCIAL_HAS_OTHER_REVENUE = new Field("financial.has_other_revenue",
        "Other Revenue", {
            type: FieldType.BOOLEAN,
            extendUpdate: (value, comp) => {
                const expenses = Field.FINANCIAL_OTHER_REVENUE.get(comp);
                Field.FINANCIAL_OTHER_REVENUE.update(expenses, comp);
            }
        });

    static FINANCIAL_OTHER_REVENUE = new Field("financial.other_revenue", "Other Revenue", {
        default: (comp) => {
            return [{
                id: nanoid(),
                title: 'Parking',
                value: 0
            }, {
                id: nanoid(),
                title: 'Laundry',
                value: 0
            }];
        }
    });

    static FINANCIAL_TOTAL_OTHER_REVENUE = new Field("financial.total_other_revenue", "Other Revenue - Total", {
        type: FieldType.CURRENCY,
        derive: obj => {
            let hasOtherRevenue = Field.FINANCIAL_HAS_OTHER_REVENUE.get(obj);
            if (hasOtherRevenue === true) {
                let items = Field.FINANCIAL_OTHER_REVENUE.get(obj) || [];
                return sumBy(items, i => i.value || 0);
            }
            return 0;
        },
        dependsOn: () => [Field.FINANCIAL_HAS_OTHER_REVENUE, Field.FINANCIAL_OTHER_REVENUE]
    });

    static FINANCIAL_OCCUPANCY_PERCENT = new Field("financial.reported_occupancy_percent", "Reported Occupancy", {
        type: FieldType.PERCENT
    });

    static LEASE_TYPE = new Field("lease.lease_type", "Lease Type", {
        values: ["New Lease", "Renewal", "Expansion", "Other"]
        // values: , "Other"]
    });
    static LEASE_TYPE_OTHER = new Field("lease.lease_type_other", {
        [FieldLabel.DEFAULT]: "Lease Type",
        [FieldLabel.FORM]: "Specify Lease Type"
    });
    static LEASE_RECOVERY = new Field("lease.recovery", {
        [FieldLabel.DEFAULT]: "Lease Recovery Type",
        [FieldLabel.SHORT]: "Recovery Type",
    }, {
        values: ["Triple Net", "Gross", "Single Net", "Double Net", "Modified Gross (Semi-Gross", "Absolute Net"]
    });

    static LEASE_BASE_LEASE_RATE = new Field("lease.base_rate", {
        [FieldLabel.DEFAULT]: o => `Net Effective Rent (Lease Rate) per Square ${FieldOptions.METRIC.is(o)?"Metre":"Foot"}`,
        [FieldLabel.SHORT]: o => `NER / ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"}`
    }, {
        type: FieldType.CURRENCY,
        roundToDollar: false,
        toMetric: 1/AreaUnit.FT2_TO_M2
    });

    static LEASE_RATE_JUMPS = new Field("lease.rate_jumps", "Lease Rate Escalations", {
        default: comp => {
            return [{id: nanoid(), month: 24, value: ""}]
        },
        renderer: (jumps, comp, options) => {
            if (!jumps) return options.nullValue;
            return jumps.map(j => `Month ${j.month} → $${Field.NUMBER_FORMATTER.format(j.value)}`)
        }
    });

    static LEASE_START_DATE = new Field("lease.start_date", "Lease Start Date", {
        type: FieldType.DATE
    });

    static LEASE_MONTH_TO_MONTH = new Field("lease.month_to_month", "Month to Month Lease", {
        type: FieldType.BOOLEAN,
        extendUpdate: (value, comp) => {
            if (value === true) {
                Field.LEASE_TERM.update(null, comp);
            }
        }
    });

    static LEASE_TERM = new Field("lease.term", {
        [FieldLabel.DEFAULT]: "Term of Lease",
        [FieldLabel.SHORT]: "Lease Term",
        [FieldLabel.REPORT_HEADER]: "Term of Lease (months)"
    }, {
        type: FieldType.INTEGER,
        unit: "Months",
        derive: (comp, options) => {
            if (Field.LEASE_MONTH_TO_MONTH.get(comp) === true) {
                if (options && options.target === FieldLabel.REPORT_BODY) return "month to month"
            }
            return 0;
        },
        shouldDerive: comp => {
            return Field.LEASE_MONTH_TO_MONTH.get(comp) === true;
        },
        dependsOn: () => [Field.LEASE_MONTH_TO_MONTH]
    });

    static LEASE_YEAR1_RATE = new Field("lease.year1_rate", {
        [FieldLabel.DEFAULT]: o => `Year 1 Lease Rate per Square ${FieldOptions.METRIC.is(o)?"Metre":"Foot"}`,
        [FieldLabel.SHORT]: o => `Year 1 Lease Rate / ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"}`,
        [FieldLabel.REPORT_HEADER]: o => `Year 1 Lease Rate / ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"}`,
    }, {
        type: FieldType.CURRENCY,
        roundToDollar: false,
        toMetric: 1/AreaUnit.FT2_TO_M2
    });

    static LEASE_AVERAGE_RATE_OVER_TERM = new Field("lease.average_rate", {
        [FieldLabel.DEFAULT]: o => `Average Lease Rate Over Term per Square ${FieldOptions.METRIC.is(o)?"Metre":"Foot"}`,
        [FieldLabel.SHORT]: o => `Avg Rate / ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"}`,
        [FieldLabel.REPORT_HEADER]: o => `Avg Lease Rate Over Term / ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"}`
    }, {
        type: FieldType.CURRENCY,
        roundToDollar: false,
        derive: comp => {
            let jumps = Field.LEASE_RATE_JUMPS.get(comp);
            if (jumps) {
                const base = Field.LEASE_YEAR1_RATE.get(comp);
                const term = Field.LEASE_TERM.get(comp);
                if (base && term) {
                    jumps = sortBy(jumps.filter(it => it.month && it.value), it => it.month);
                    if (jumps.length === 0) {
                        return base;
                    }
                    else {
                        const j = [{month: 0, value: base}, ...jumps, {month: term, value: 0}];
                        let avg = 0;
                        for (let i = 0; i < j.length-1; i++) {
                            avg += (j[i+1].month - j[i].month) * j[i].value;
                        }
                        return avg / term;
                    }
                }
            }

            return 0;
        },
        toMetric: 1/AreaUnit.FT2_TO_M2,
        indexed: true,
        dependsOn: () => [Field.LEASE_RATE_JUMPS, Field.LEASE_YEAR1_RATE, Field.LEASE_TERM]
    });

    static LEASE_RATE_PER_MONTH = new Field("lease.rate_per_month", {
        [FieldLabel.DEFAULT]: o => `Lease Rate per Month`,
    }, {
        type: FieldType.CURRENCY,
        roundToDollar: false
    });

    static LEASE_RATE_PER_ACRE_PER_MONTH = new Field("lease.rate_per_acre_per_month", {
        [FieldLabel.DEFAULT]: o => `Lease Rate per Acre per Month`,
        [FieldLabel.SHORT]: o => `Lease Rate/Acre/Month`,
    }, {
        type: FieldType.CURRENCY,
        roundToDollar: false
    });

    static LEASE_USE_RATE_CALCULATOR = new Field("lease.use_rate_calculator",
        "Lease Rate Calculator", {
            type: FieldType.BOOLEAN,
            extendUpdate: (value, comp) => {
                const jumps = Field.LEASE_RATE_JUMPS.get(comp);
                Field.LEASE_RATE_JUMPS.update(jumps, comp);
            }
        });

    static LEASE_HAS_RENEWAL_OPTION = new Field("lease.has_renewal_option", "Renewal Option?", {
        type: FieldType.BOOLEAN,
    });
    static LEASE_RENEWAL_OPTIONS_DESC = new Field("lease.renewal_option_desc", "Describe Renewal Option");

    static LEASE_TENANT_IMPROVEMENT_ALLOWANCE = new Field("lease.tenant_improvement_allowance", {
        [FieldLabel.DEFAULT]: o => `Tenant Improvement Allowance per Square ${FieldOptions.METRIC.is(o)?"Metre":"Foot"}`,
        [FieldLabel.SHORT]: o => `TI Allowance / ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"}`,
        [FieldLabel.REPORT_HEADER]: o => `TI Allowance / ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"}`
    }, {
        type: FieldType.CURRENCY,
        toMetric: 1/AreaUnit.FT2_TO_M2
    });
    static LEASE_TENANT_IMPROVEMENT_ALLOWANCE_DESC = new Field("lease.tenant_improvement_allowance_desc", {
        [FieldLabel.DEFAULT]: "Tenant Improvement Allowance Description",
        [FieldLabel.SHORT]: "TI Allowance Desc.",
        [FieldLabel.REPORT_HEADER]: "TI Allowance Description",
    });
    static LEASE_FREE_RENT = new Field("lease.free_rent", "Free Rent", {
        type: FieldType.INTEGER,
        unit: "Months"
    });
    static LEASE_FREE_RENT_DESC = new Field("lease.free_rent_desc", "Free Rent Description", );

    static LEASE_ADDITIONAL_RENT = new Field("lease.additional_rent", {
        [FieldLabel.DEFAULT]: o => `Additional Rent per Square ${FieldOptions.METRIC.is(o)?"Metre":"Foot"}`,
        [FieldLabel.SHORT]: o => `Additional Rent / ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"}`,
        [FieldLabel.REPORT_HEADER]: o => `Additional Rent / ${FieldOptions.METRIC.is(o)?"SQM":"SQFT"}`,
    }, {
        type: FieldType.CURRENCY,
        toMetric: 1/AreaUnit.FT2_TO_M2,
        roundToDollar: false
    });

    static LEASE_TENANT_NAME = new Field("lease.tenant_name", "Tenant Name", );
    static LEASE_LANDLORD_NAME = new Field("lease.landlord_name", "Landlord Name", );

    static FT2_FORMATTER= new Intl.NumberFormat("en-US", {
    });

    static ACRE_FORMATTER= new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 3
    });

    static CURRENCY_FORMATTER = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
    });

    static CURRENCY_FORMATTER2 = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    static NUMBER_FORMATTER = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2
    });

    static NUMBER_FORMATTER2 = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    static NUMBER_FORMATTER3 = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3
    });

    static NUMBER_PARSER = {
        parse: v => {
            if (!v) {
                return null;
            }
            return Number(v.toString().replace(/[^0-9.-]+/g,""));
        }
    };

    static PERCENT_FORMATTER = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2
    });

    static PERCENT_FORMATTER2 = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    constructor(path, labels, options) {
        this.path = path;
        this.group = path.indexOf(".") > -1 ? path.split(".")[0] : null;
        this.name = path.split(".").slice(-1)[0];
        this.labels = typeof labels === "string" ? {[FieldLabel.DEFAULT]: labels} : labels;

        let opts = options || {};
        this.type = "type" in opts ? opts.type : FieldType.STRING;

        let defalt = opts["default"];
        if (defalt) {
            this.default = typeof defalt === "function" ? defalt : () => defalt;
        }
        else this.default = null;

        let vals = opts["values"];
        if (vals) {
            this.hasValues = true;
            this.values = typeof vals === "function" ? vals : () => vals;
            this.isChoice = opts["choice"] === true;
            this.isMultiple = opts["multiple"] === true;
        }
        else {
            this.hasValues = false;
            this.isChoice = false;
            this.values = null;
            this.isMultiple = false;
        }

        let unit = opts["unit"];
        if (unit) {
            this.hasUnit = true;
            this.unit = typeof unit === "function" ? unit : () => typeof unit === "string" ? {symbol: unit} : unit;
        }
        else {
            this.hasUnit = false;
            this.unit = null;
        }

        this.derive = opts["derive"];
        this.renderer = opts["renderer"];
        this.shouldDerive = opts["shouldDerive"] || (() => true);
        this.extendUpdate = opts["extendUpdate"];

        this.formatWithComma = "formatWithComma" in opts ? opts["formatWithComma"] :
          (this.type === FieldType.INTEGER || this.type === FieldType.AREA);
        this.roundToDollar = "roundToDollar" in opts ? opts["roundToDollar"] : true;
        this.isNegative = opts["negative"] === true;
        this.showFormLabel ="showFormLabel" in opts ? opts["showFormLabel"] : true;
        this.toMetric = opts["toMetric"] || 0;
        this.isIndexed = opts["indexed"] === true;
        this.isCopyable = opts["copyable"] !== false;
        this.dependsOn = opts["dependsOn"] || (() => []);
        this.dependants = [];
        this.numDecimals = opts["numDecimals"];
        this.formatCurrency = opts["formatCurrency"] || null;
        this.placeholder = opts["placeholder"] || null;
    }

    isDerived(comp) {
        return !!this.derive && this.shouldDerive(comp);
    }

    parseLabel(label) {
        // if (typeof convertHtmlToReact !== "function") return label;
        // return convertHtmlToReact(label)[0]
        return label;
    }

    label(display= FieldLabel.DEFAULT, options = {}) {
        let l = null;
        if (display in this.labels) l = this.labels[display];
        if (!(display in this.labels)) {
            if (display === FieldLabel.REPORT_BODY) {
                return this.label(FieldLabel.REPORT_HEADER, options);
            }
            if (display === FieldLabel.SEARCH) {
                return this.label(FieldLabel.SHORT, options);
            }
        }
        if (l === null) l = this.labels[FieldLabel.DEFAULT];
        if (typeof l === "function") l = l(options);
        return this.parseLabel(l);
    }

    render(value, options) {
        if (value != null && typeof value === "object") value = this.get(value, options);

        if (value == null && this.type === FieldType.BOOLEAN) return "No";
        if (value === 0 && (options||{}).zeroAsNull === true) value = null;
        if (value != null) {
            if (this.renderer) return this.renderer(value, this, options);

            if (this.isMultiple && Array.isArray(value)) {
                return value.map(v => this.render(v, options)).join(", ");
            }

            if (this.type === FieldType.PROPERTY_TYPE) {
                return propertyNameShort(value);
            }
            if (this.type === FieldType.CURRENCY) {
                if (this.formatCurrency) return this.formatCurrency(value);
                if (this.numDecimals === 2) {
                    return Field.CURRENCY_FORMATTER2.format(value)
                }
                return this.roundToDollar ?
                    Field.CURRENCY_FORMATTER.format(Math.round(value)).replace(/\D00$/, '') :
                    Field.CURRENCY_FORMATTER.format(value);
            }
            if (this.type === FieldType.PERCENT) {
                return (this.numDecimals===2?Field.PERCENT_FORMATTER2:Field.PERCENT_FORMATTER).format(value) + "%";
            }
            if (this.type === FieldType.NUMBER || this.type === FieldType.AREA) {
                return (
                    this.numDecimals===3?Field.NUMBER_FORMATTER3:
                        (this.numDecimals===2?Field.NUMBER_FORMATTER2:Field.NUMBER_FORMATTER)).format(value);
            }
            if (this.type === FieldType.DATE) {
                let date = Comp.date(value);
                if (options && options.fromNow === true) {
                    return moment(date).fromNow();
                }
                else {
                    return date instanceof Date ? date.toLocaleDateString("en-US", {
                        month: "short", day: "2-digit", year: "numeric"
                    }) : date;
                }
            }
            if (this.type === FieldType.BOOLEAN) {
                return value === true ? "Yes" : "No";
            }

            if (this.type === FieldType.INTEGER) {
                if (this.formatWithComma && typeof value === 'number') {
                    return value.toLocaleString();
                }
            }

            if (this.type === FieldType.GEO) {
                const g = Comp.point(value);
                const lon = Math.round(100*g[0]) / 100;
                const lat = Math.round(100*g[1]) / 100;

                return `${Math.abs(lat)}\u00B0 ${(lat<0)?'S':'N'}, ${Math.abs(lon)}\u00B0 ${(lon<0)?'W':'E'}`
            }

            // fallback to catch complex fields
            if (Array.isArray(value) || typeof value === 'object') {
                return JSON.stringify(value);
            }
        }
        return value != null ? value : ((options && options.nullValue) || value);
    }

    get(obj, options={}) {
        let isDetached = (obj.detached||[]).includes(this.path);
        let v = this.isDerived(obj) && !isDetached ? this.derive(obj, options) : get(obj, this.path);
        v = typeof v === "undefined" || v === null ? (this.default ? this.default(obj) : v) : v;
        if (FieldOptions.METRIC.is(options) && this.toMetric) {
            v *= this.toMetric;
        }
        return v;
    }

    set(obj, value) {
        if (typeof value === 'undefined') value = null;
        set(obj, this.path, value);
    }

    update(value, obj) {
        value = typeof value === "undefined" ? null : value;

        if (!value && this.type !== FieldType.STRING) {
            unset(obj, this.path);
        }
        else {
            set(obj, this.path, value);
        }

        if (this.extendUpdate) {
            this.extendUpdate(value, obj);
        }
    }

    convert(comp) {
        let v = this.get(comp);
        if (v) {
            let u = this.unit(comp);
            let o = u.other();
            return [o.format(v * u.factor), o];
        }
        return null;
    }
}

export class Fields {

    static INSTANCE = null;

    constructor() {
        this.map = new Map();
        this.dateFields = [];
    }

    register(field) {
        this.map.set(field.path, field);
        if (field.type === FieldType.DATE) this.dateFields.push(field);
    }

    link() {
        this.map.forEach((f, p) => {
            f.dependsOn().forEach(d => d.dependants.push(f));
        });
    }

    static get() {
        if (Fields.INSTANCE === null) {
            let fields = new Fields();
            fields.register(Field.TYPE);
            fields.register(Field.COMP_ID);
            fields.register(Field.TITLE);
            fields.register(Field.CREATED);
            fields.register(Field.UPDATED);
            fields.register(Field.COMMENTS);
            fields.register(Field.BRIEF);
            //fields.register(Field.NOTES);
            fields.register(Field.VALUE);
            fields.register(Field.GEO);
            fields.register(Field.GENERAL_DEVELOPMENT_NAME);
            fields.register(Field.GENERAL_LEGAL_DESCRIPTION);
            fields.register(Field.GENERAL_PID);
            fields.register(Field.GENERAL_LOCATION);
            fields.register(Field.ADDRESS_STREET);
            fields.register(Field.ADDRESS_UNIT);
            fields.register(Field.ADDRESS_CITY);
            fields.register(Field.ADDRESS_MUNICIPALITY);
            fields.register(Field.ADDRESS_COUNTRY);
            fields.register(Field.ADDRESS_POSTAL);
            fields.register(Field.ADDRESS_REGION);
            fields.register(Field.ADDRESS_STREET_WITH_CITY);
            fields.register(Field.CONFIRMATION_COMMENTS);
            fields.register(Field.CONFIRMATION_DATE);
            fields.register(Field.CONFIRMATION_LISTING_DATE);
            fields.register(Field.CONFIRMATION_SOURCE);
            fields.register(Field.CONFIRMATION_MLS_NUM);
            //fields.register(Field.CONFIRMATION_IS_CURRENT_LISTING);
            fields.register(Field.IMPROVEMENT_BASEMENT_AREA);
            fields.register(Field.IMPROVEMENT_BASEMENT_FINISH);
            fields.register(Field.IMPROVEMENT_BASEMENT_FINISH_MODE);
            fields.register(Field.IMPROVEMENT_BASEMENT_FINISH_PERCENT);
            fields.register(Field.IMPROVEMENT_BASEMENT_FINISH_SF);
            fields.register(Field.IMPROVEMENT_BUILDING_AREA_SOURCE);
            fields.register(Field.IMPROVEMENT_BUILDING_CLASS);
            fields.register(Field.IMPROVEMENT_CONSTRUCTION_QUALITY);
            fields.register(Field.IMPROVEMENT_GROSS_BUILDING_AREA);
            fields.register(Field.IMPROVEMENT_MAIN_FLOOR_PLATE_AREA);
            fields.register(Field.IMPROVEMENT_OFFICE_AREA);
            fields.register(Field.IMPROVEMENT_OFFICE_FINISH_PERCENT);
            fields.register(Field.IMPROVEMENT_TOTAL_PROPOSED_GROSS_FLOOR_AREA);
            fields.register(Field.IMPROVEMENT_NUM_BUILDINGS);
            fields.register(Field.IMPROVEMENT_NUM_PARKING_SPACES);
            fields.register(Field.IMPROVEMENT_NUM_STORIES);
            fields.register(Field.IMPROVEMENT_NUM_UNITS);
            fields.register(Field.IMPROVEMENT_NUM_PADS);
            fields.register(Field.IMPROVEMENT_NUM_HOLES);
            fields.register(Field.IMPROVEMENT_COURSE_YARDAGE);
            fields.register(Field.IMPROVEMENT_COURSE_PAR);
            fields.register(Field.IMPROVEMENT_PARKING_RATIO);
            fields.register(Field.IMPROVEMENT_PARKING_TYPE);
            fields.register(Field.IMPROVEMENT_PARKING_COMMENTS);
            fields.register(Field.IMPROVEMENT_PROPERTY_CONDITION);
            fields.register(Field.IMPROVEMENT_RENTABLE_AREA);
            fields.register(Field.IMPROVEMENT_LEASED_AREA);
            fields.register(Field.IMPROVEMENT_LEASED_AREA_SOURCE);
            fields.register(Field.IMPROVEMENT_LAND_TO_BUILDING_RATIO);
            fields.register(Field.IMPROVEMENT_FLOOR_SPACE_RATIO);
            fields.register(Field.IMPROVEMENT_FLOOR_SPACE_INDEX);
            fields.register(Field.IMPROVEMENT_YEAR_BUILT);
            fields.register(Field.IMPROVEMENT_NUM_BEDROOMS);
            fields.register(Field.IMPROVEMENT_NUM_RENOVATED_UNITS);
            fields.register(Field.IMPROVEMENT_NUM_BATHROOMS);
            fields.register(Field.IMPROVEMENT_HAS_ELEVATOR);
            fields.register(Field.IMPROVEMENT_FEATURES);
            fields.register(Field.IMPROVEMENT_HAS_SUITE_MIX);
            fields.register(Field.IMPROVEMENT_SUITE_MIX);
            fields.register(Field.IMPROVEMENT_NUM_BACHELOR_UNITS);
            fields.register(Field.IMPROVEMENT_NUM_1BEDROOM_UNITS);
            fields.register(Field.IMPROVEMENT_NUM_2BEDROOM_UNITS);
            fields.register(Field.IMPROVEMENT_NUM_3BEDROOM_UNITS);
            fields.register(Field.IMPROVEMENT_NUM_4BEDROOM_UNITS);
            fields.register(Field.IMPROVEMENT_NUM_SELF_SERVICE_BAYS);
            fields.register(Field.IMPROVEMENT_NUM_AUTO_TUNNEL_BAYS);
            fields.register(Field.IMPROVEMENT_NUM_BAYS);
            fields.register(Field.IMPROVEMENT_LOADING_BAYS);
            fields.register(Field.IMPROVEMENT_NUM_ROOMS);
            fields.register(Field.IMPROVEMENT_ROOMS_PER_UNIT);
            fields.register(Field.IMPROVEMENT_CLEARING_HEIGHT);
            fields.register(Field.IMPROVEMENT_AMENITIES);
            fields.register(Field.IMPROVEMENT_OUTBUILDING_COMMENTS);
            fields.register(Field.IMPROVEMENT_OUTBUILDING_CONTRIBUTORY_VALUE);
            fields.register(Field.IMPROVEMENT_DWELLING_COMMENTS);
            fields.register(Field.IMPROVEMENT_DWELLING_CONTRIBUTORY_VALUE);
            fields.register(Field.PHYSICAL_CORNER_LOT);
            fields.register(Field.PHYSICAL_EXISTING_USE);
            fields.register(Field.PHYSICAL_FRONTAGE);
            fields.register(Field.PHYSICAL_MOORAGE);
            fields.register(Field.PHYSICAL_FRONTAGE_STREET);
            fields.register(Field.PHYSICAL_IN_FLOOD_PLAIN);
            fields.register(Field.PHYSICAL_IN_ALR);
            fields.register(Field.PHYSICAL_LAND_AREA_UNITS);
            fields.register(Field.PHYSICAL_PROPERTY_TYPE);
            fields.register(Field.PHYSICAL_PROPERTY_SUBTYPE);
            fields.register(Field.PHYSICAL_PROPOSED_USE);
            fields.register(Field.PHYSICAL_SHAPE);
            fields.register(Field.PHYSICAL_TOPOGRAPHY);
            fields.register(Field.PHYSICAL_ACCESS);
            fields.register(Field.PHYSICAL_EXPOSURE);
            fields.register(Field.PHYSICAL_TOTAL_LAND_AREA);
            fields.register(Field.PHYSICAL_TOTAL_LAND_AREA_ACRE);
            fields.register(Field.PHYSICAL_TOTAL_LAND_AREA_SF);
            fields.register(Field.PHYSICAL_EFFECTIVE_LAND_AREA);
            fields.register(Field.PHYSICAL_EFFECTIVE_LAND_AREA_ACRE);
            fields.register(Field.PHYSICAL_EFFECTIVE_LAND_AREA_SF);
            fields.register(Field.PHYSICAL_ARABLE_AREA_PERCENT);
            fields.register(Field.PHYSICAL_ARABLE_AREA);
            fields.register(Field.PHYSICAL_TOTAL_BUILDABLE_AREA);
            fields.register(Field.PHYSICAL_TOTAL_BUILDABLE_AREA_SF);
            fields.register(Field.PHYSICAL_TOTAL_BUILDABLE_AREA_ACRE);
            fields.register(Field.PHYSICAL_BUILDABLE_AREA_PERCENT_SF);
            fields.register(Field.PHYSICAL_BUILDABLE_AREA_PERCENT_ACRE);
            fields.register(Field.PHYSICAL_FORESHORE_LEASE_AREA);
            fields.register(Field.PHYSICAL_WATER_LOT_LEASE_AREA);
            fields.register(Field.PHYSICAL_ZONING_CODE);
            fields.register(Field.PHYSICAL_ZONING_DESC);
            fields.register(Field.PHYSICAL_OCP_DESIGNATION);
            fields.register(Field.PHYSICAL_DEVELOPMENT_TYPE);
            fields.register(Field.PHYSICAL_NUM_DEVELOPABLE_LOTS);
            fields.register(Field.PHYSICAL_NUM_DEVELOPABLE_UNITS);
            fields.register(Field.PHYSICAL_SOIL_TYPE);
            fields.register(Field.PHYSICAL_CLI_CLASS);
            fields.register(Field.PHYSICAL_DRAINAGE);
            fields.register(Field.PHYSICAL_GREENBELT_CONSERVATION_AUTHORITY);
            fields.register(Field.PHYSICAL_DEVELOPMENT_TIMEFRAME);
            fields.register(Field.PHYSICAL_INTERIM_USE);
            fields.register(Field.PHYSICAL_PLANNING_STATUS);
            fields.register(Field.PHYSICAL_NUM_PROPOSED_APPROVED_UNITS);
            fields.register(Field.PHYSICAL_APPLICATION_NUM);
            fields.register(Field.PHYSICAL_OPEN_SPACE_ESA);
            fields.register(Field.PHYSICAL_DRAFT_PLAN_STATUS);
            fields.register(Field.PHYSICAL_BUILDING_SITE_AREA);
            fields.register(Field.PHYSICAL_OTHER_SITE_AREA);
            fields.register(Field.SALES_CONDITIONS);
            fields.register(Field.SALES_DAYS_ON_MARKET);
            fields.register(Field.SALES_PRICE);
            fields.register(Field.SALES_PRICE_PER_SQFT);
            fields.register(Field.SALES_PRICE_PER_SQFT_WITH_BASEMENT);
            fields.register(Field.SALES_PRICE_PER_SQFT_WITHOUT_BASEMENT);
            fields.register(Field.SALES_PRICE_PER_SQFT);
            fields.register(Field.SALES_PRICE_ADJUSTMENT);
            fields.register(Field.SALES_EXPENDITURES_POST_SALE);
            fields.register(Field.SALES_CONTRIBUTORY_IMPROVEMENTS);
            fields.register(Field.SALES_DEMOLITION_COSTS);
            fields.register(Field.SALES_EFFECTIVE_PRICE);
            fields.register(Field.SALES_PROPERTY_RIGHTS);
            fields.register(Field.SALES_DATE);
            fields.register(Field.SALES_CLOSING_DATE);
            fields.register(Field.SALES_STATUS);
            fields.register(Field.SALES_BUYER);
            fields.register(Field.SALES_SELLER);
            fields.register(Field.SALES_FINANCING_DESCRIPTION);
            fields.register(Field.SALES_PRICE_PER_SF_TOTAL);
            fields.register(Field.SALES_PRICE_PER_SF_EFFECTIVE);
            fields.register(Field.SALES_PRICE_PER_ACRE_TOTAL);
            fields.register(Field.SALES_EFFECTIVE_PRICE_PER_ACRE_TOTAL);
            fields.register(Field.SALES_PRICE_PER_ACRE_EFFECTIVE);
            fields.register(Field.SALES_PRICE_PER_UNIT);
            fields.register(Field.SALES_PRICE_PER_PAD);
            fields.register(Field.SALES_PRICE_PER_HOLE);
            fields.register(Field.SALES_PRICE_PER_SF_BUILDING_AREA);
            fields.register(Field.SALES_PRICE_PER_ACRE_BUILDING_AREA);
            fields.register(Field.SALES_PRICE_PER_FRONTAGE);
            fields.register(Field.SALES_PRICE_PER_MOORAGE);
            fields.register(Field.SALES_PRICE_PER_MAIN_FLOOR_PLATE_AREA);
            fields.register(Field.SALES_PRICE_PER_ARABLE_AREA);
            fields.register(Field.SALES_EFFECTIVE_PRICE_PER_ARABLE_AREA);
            fields.register(Field.SALES_DEVELOPMENT_COST_CHARGES);
            fields.register(Field.SALES_DENSITY_PER_ACRE_TOTAL);
            fields.register(Field.SALES_DENSITY_PER_ACRE_EFFECTIVE);
            fields.register(Field.SALES_PRICE_PER_DEVELOPABLE);
            fields.register(Field.SALES_PRICE_PER_PROPOSED_GROSS_FLOOR_AREA);
            fields.register(Field.SALES_USE_EFFECTIVE_SALE_PRICE);
            fields.register(Field.SALES_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE);
            fields.register(Field.SALES_IS_RESALE);
            fields.register(Field.SALES_FUEL_SALES);
            fields.register(Field.SALES_PRICE_PER_LITRE);
            fields.register(Field.SALES_FUEL_MARGIN_PER_LITRE);
            fields.register(Field.SALES_CROSS_LEASE_PER_LITRE);
            fields.register(Field.SALES_COMBINED_FUEL_MARGIN);
            fields.register(Field.SALES_SERVICE_STATION_BRAND);
            fields.register(Field.SALES_PRICE_PER_LITRE);
            fields.register(Field.SALES_PRICE_PER_BAY);
            fields.register(Field.SALES_PRICE_PER_ROOM);
            fields.register(Field.SALES_PRICE_PER_BEDROOM);
            fields.register(Field.SALES_CONVENIENCE_STORE_SALES);
            fields.register(Field.SALES_CONVENIENCE_STORE_MARGIN);
            fields.register(Field.SALES_CONVENIENCE_STORE_SALES_AND_MARGIN);
            fields.register(Field.SALES_CAR_WASH_SALES);
            fields.register(Field.SALES_CAR_WASH_MARGIN);
            fields.register(Field.SALES_CAR_WASH_SALES_AND_MARGIN);
            fields.register(Field.SALES_FAST_FOOD_SALES);
            fields.register(Field.SALES_FAST_FOOD_MARGIN);
            fields.register(Field.SALES_FAST_FOOD_SALES_AND_MARGIN);
            fields.register(Field.SALES_OTHER_SALES);
            fields.register(Field.SALES_OTHER_MARGIN);
            fields.register(Field.SALES_OTHER_SALES_AND_MARGIN);
            fields.register(Field.SALES_CONTRIBUTORY_IMPROVEMENTS)
            fields.register(Field.UTILITIES_HYDRO);
            fields.register(Field.UTILITIES_NATURAL_GAS);
            fields.register(Field.UTILITIES_SEWER);
            fields.register(Field.UTILITIES_SEWER_OTHER);
            fields.register(Field.UTILITIES_WATER);
            fields.register(Field.UTILITIES_WATER_OTHER);
            fields.register(Field.UTILITIES_DESCRIPTION);

            fields.register(Field.FINANCIAL_USE_EFFECTIVE_SALE_PRICE);
            fields.register(Field.FINANCIAL_SALE_PRICE_OR_EFFECTIVE_SALE_PRICE);
            fields.register(Field.FINANCIAL_GROSS_BUILDABLE_AREA_PRICE);
            fields.register(Field.FINANCIAL_RENTABLE_AREA_PRICE);
            fields.register(Field.FINANCIAL_POTENTIAL_GROSS_INCOME);
            fields.register(Field.FINANCIAL_EFFECTIVE_GROSS_INCOME);
            fields.register(Field.FINANCIAL_EFFECTIVE_GROSS_INCOME_PER_UNIT);
            fields.register(Field.FINANCIAL_EFFECTIVE_GROSS_INCOME_PER_UNIT_PER_MONTH);
            fields.register(Field.FINANCIAL_EXPENSES);
            fields.register(Field.FINANCIAL_TOTAL_EXPENSES);
            fields.register(Field.FINANCIAL_USE_EXPENSE_CALCULATOR);
            fields.register(Field.FINANCIAL_BASE_RENT);
            fields.register(Field.FINANCIAL_OTHER_REVENUE);
            fields.register(Field.FINANCIAL_HAS_OTHER_REVENUE);
            // fields.register(Field.FINANCIAL_VACANCY_COLLECTION_LOSS);
            fields.register(Field.FINANCIAL_VACANCY_COLLECTION_LOSS_VALUE);
            fields.register(Field.FINANCIAL_NET_OPERATING_INCOME);
            fields.register(Field.FINANCIAL_NET_OPERATING_INCOME_PER_SQFT);
            fields.register(Field.FINANCIAL_NET_OPERATING_INCOME_PER_UNIT);
            fields.register(Field.FINANCIAL_NET_OPERATING_INCOME_PER_UNIT_PER_MONTH);
            fields.register(Field.FINANCIAL_POTENTIAL_GROSS_INCOME_MULTIPLIER);
            fields.register(Field.FINANCIAL_EFFECTIVE_GROSS_INCOME_MULTIPLIER);
            fields.register(Field.FINANCIAL_CAP_RATE);
            fields.register(Field.FINANCIAL_EXPENSE_RATIO);
            fields.register(Field.FINANCIAL_MONTHLY_RENT);
            fields.register(Field.FINANCIAL_RENTAL_TERM);
            fields.register(Field.FINANCIAL_RENTAL_START_DATE);
            fields.register(Field.FINANCIAL_UTILITIES_INCLUDED);
            fields.register(Field.FINANCIAL_SEE_ADDITIONAL);
            fields.register(Field.FINANCIAL_ANNUAL_EXPENSE_INCREASE);
            fields.register(Field.FINANCIAL_DISCOUNT_RATE);
            fields.register(Field.FINANCIAL_TERMINAL_CAP_RATE);
            fields.register(Field.FINANCIAL_YEAR_ONE_CAP_RATE);
            fields.register(Field.FINANCIAL_OCCUPANCY_PERCENT);
            fields.register(Field.LEASE_TYPE);
            fields.register(Field.LEASE_TYPE_OTHER);
            fields.register(Field.LEASE_RECOVERY);
            fields.register(Field.LEASE_BASE_LEASE_RATE);
            fields.register(Field.LEASE_RATE_JUMPS);
            fields.register(Field.LEASE_START_DATE);
            fields.register(Field.LEASE_MONTH_TO_MONTH);
            fields.register(Field.LEASE_TERM);
            fields.register(Field.LEASE_YEAR1_RATE);
            fields.register(Field.LEASE_AVERAGE_RATE_OVER_TERM);
            fields.register(Field.LEASE_RATE_PER_MONTH);
            fields.register(Field.LEASE_RATE_PER_ACRE_PER_MONTH);
            fields.register(Field.LEASE_USE_RATE_CALCULATOR);
            fields.register(Field.LEASE_HAS_RENEWAL_OPTION);
            fields.register(Field.LEASE_RENEWAL_OPTIONS_DESC);
            fields.register(Field.LEASE_TENANT_IMPROVEMENT_ALLOWANCE);
            fields.register(Field.LEASE_TENANT_IMPROVEMENT_ALLOWANCE_DESC);
            fields.register(Field.LEASE_FREE_RENT);
            fields.register(Field.LEASE_FREE_RENT_DESC);
            fields.register(Field.LEASE_ADDITIONAL_RENT);
            fields.register(Field.LEASE_TENANT_NAME);
            fields.register(Field.LEASE_LANDLORD_NAME);
            fields.link();
            Fields.INSTANCE = fields;
        }
        return Fields.INSTANCE;
    }

    static list() {
        return Array.from(Fields.get().map.values());
    }

    static groups() {
        const g = {};
        Fields.list().forEach(f => {
            const l = g[f.group||""];
            if (l) l.push(f)
            else g[f.group||""] = [f];
        })
        return g;
    }

    static lookup(path) {
        if (typeof path === 'object' && 'path' in path) path = path.path;
        return Fields.get().map.get(path);
    }

    static dependants(path) {
        const field = Fields.lookup(path);
        if (!field) {
            return [];
        }

        const d = [];
        const q = [field];
        while (q.length > 0) {
            const f = q.shift();
            if (!d.includes(f)) {
                d.push(f);
                f.dependants.forEach(g => q.push(g));
            }
        }

        d.shift();
        return Array.from(new Set(d));
    }

    static ordered() {
        const g = new Map();
        Fields.list().forEach((f, p) => {
            g.set(f.path, f.dependsOn());
        });

        const ordered = [];
        while(g.size > 0) {
            const i = ordered.length;

            // grab all the leaves
            g.forEach((deps, path) => {
                if (deps.length > 0) {
                    ordered.push(path);
                }
            })

            const prune = ordered.slice(i);

            // prune all the new leaves
            g.forEach((deps, path) => {
                remove(deps, it => prune.includes(it));
            })
            prune.forEach(p => g.remove(p));
        }

        return ordered;
    }

    static set(path, obj, value) {
        if (typeof value === 'undefined') value = null;
        set(obj, path, value);
    }

    static dates() {
        return Fields.get().dateFields;
    }
}
